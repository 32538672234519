import React from 'react';
import ReactDOM from 'react-dom';

function ChildSeatRow({ageLabel, amountLabel, index, childseats, setChildseats, changeAge, changeAmount}) {

    return (
        <div className='row childseatRow mb-3'>
            <div className='col-11'>
                <div className='row'>
                    <div className='col-md-6 mb-3 mb-md-0'>
                        <label htmlFor={"childseat-age-" + index} >{ageLabel??'Age'}</label><br/>
                        <input type="text" id={'childseat-age-' + index} value={childseats[index].childseat_age} onChange={(e)=> {changeAge(e.target.value, index);}} className="form-control childseatInput w-100" name="childseat_ages[]" required={true} />
                    </div>
                    <div className='col-md-6'>
                        <div className='row'>
                            <div className='col-12'>
                                <label htmlFor={"childseat-amount-" + index}>{amountLabel??'Amount'}</label><br/>
                                <input type="number" id={"childseat-amount-" + index} min={1} value={childseats[index].childseat_amount} onChange={(e)=> {changeAmount(e.target.value, index);}} className="form-control childseatInput w-100" name="childseat_amounts[]" required={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-1 p-0 d-flex align-items-center">
                <button type="button" className="btn btn-danger childseatDelete p-0 px-md-2" onClick={()=> {
                        let temp = [...childseats]; 
                        temp.splice(index, 1);
                        setChildseats(temp);
                    }}
                >&times;</button>
            </div>
        </div>
    );
}

export default ChildSeatRow;

if (document.getElementsByClassName('childseatrow').length > 0) {
    Array.from(document.getElementsByClassName('childseatrow')).forEach(el => {
        var data = el.getAttribute('data');
        ReactDOM.render(<ChildSeatRow data={data} />, el);
    });
}
