import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';

function FeedbackStars(props) {
    const [changeAble, setChangeAble] = useState(true);
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [isMobile] = useState(window.screen.width < 850);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        let temp = JSON.parse(props.data);
        if (temp) {
            setError(temp.error);
            setChangeAble(temp.changeAble??true);
            setRating(temp.rating??0);
        }
    }, []);

    return (
        <>
            <div className="stars-container">
                {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                        <button
                            type="button"
                            key={index}
                            className={`btn-feedback`}
                            onClick={() => {setRating(index)}}
                            onMouseEnter={() => {if(changeAble && !isMobile){setHover(index)}}}
                            onMouseLeave={() => {if(changeAble && !isMobile){setHover(rating)}}}
                        >
                            {index <= (hover || rating) ? 
                                <i className="star mdi mdi-star on"></i>
                            :
                                <i className="star mdi mdi-star-outline off"></i>
                            }
                        </button>
                    );
                })}
                <input name='score' value={rating>0?rating:''} onInvalid={()=>{setShowError(true)}} required hidden/>
            </div>
            {showError && 
                <div className='text-danger'>
                    {error}.
                </div>
            }
        </>


    );
}

export default FeedbackStars;

if (document.getElementsByClassName('feedbackstars').length > 0) {
    Array.from(document.getElementsByClassName('feedbackstars')).forEach(el => {
        var data = el.getAttribute('data');
        ReactDOM.render(<FeedbackStars data={data} />, el);
    });
}
