import React, {useLayoutEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import CargoRow from './CargoRow';

function CargoWrapper(props) {
    const [cargo, setCargo] = useState();
    const [labels, setLabels] = useState();

    useLayoutEffect(()=>{
        let temp = JSON.parse(props.data);
        setCargo(temp?.cargo??[]);
        setLabels(temp?.labels??[]);
    }, []);

    const addRow = () => {
        let temp = [...cargo, {cargo_name:'', cargo_amount:1}];
        setCargo(temp);
    }

    return (
        <>
            <div className="cargo">
                {labels && cargo && cargo.length > 0 && cargo.map((c, i)=>{
                    return <CargoRow key={i} nameLabel={labels.cargo_name} amountLabel={labels.cargo_amount} cargo={cargo} setCargo={setCargo} index={i }/>;
                })}
            </div>
            <div>
                <button type='button' className='cargoAdd' onClick={()=>{addRow()}}>{labels?.addCargo??'add'}</button>
            </div>
        </>
    );
}

export default CargoWrapper;

if (document.getElementsByClassName('cargowrapper').length > 0) {
    Array.from(document.getElementsByClassName('cargowrapper')).forEach(el => {
        var data = el.getAttribute('data');
        ReactDOM.render(<CargoWrapper data={data} />, el);
    });
}
