import React, { useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Moment from 'react-moment';

function Chat(props) {
    const [labels, setLabels] = useState();
    const [csrf, setCsrf] = useState();
    const [comments, setComments] = useState();
    const [currentUserId, setCurrentUserId] = useState();
    const [newComment, setNewComment] = useState();
    const [orderId, setOrderId] = useState();
    const [route, setRoute] = useState();
    const [sending, setSending] = useState(false);

    useLayoutEffect(()=>{
        let temp = JSON.parse(props.data);
        setCsrf(temp?.csrf??[]);
        setComments(temp?.initalComments??[]);
        setLabels(temp?.labels??[]);
        setOrderId(temp?.orderId);
        setRoute(temp?.route);
        setCurrentUserId(temp?.currentUser);
    }, []);

    const sendMessage = async () => {
        setSending(true);
        if (newComment != '' && route) {
            const myHeaders = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrf,
            }
            
            const options = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                order_id: orderId,
                message: newComment
            })
            };
        
            const resp = await fetch(route, options);
            let data = await resp.json();
            setComments(data.comments);
            setNewComment('');
        }
        setSending(false);
    }

    const handleKeyPress = (event) => {
        if(event.key === 'Enter' && sending == false){
            sendMessage();
        }
      }

    return (
        <>
            {comments && labels && currentUserId && 
            <>
                <h4 className="header-title">{labels.title}</h4>
                <div className="comment-container" id="comment-container">
                    {comments && comments.length > 0 && comments.map((c, i) => {
                        return <div className={`comment-card ${currentUserId == c.user.id?'reverse-card':''}`} key={i}>
                                    <div className="comment-card__content">
                                        <div className="comment-card__title">
                                            {c.user && (c.user.roles.filter((x)=> { return x.name == "Admin"}).length > 0 ? 
                                                (c.user.firstname != null ? (c.user.firstname + ' (admin)' ): "Admin")
                                            :
                                                (c.user.firstname != null ? (c.user.firstname + ' ' + c.user.name): c.user.email)
                                            )}
                                        </div>
                                        <div className="comment-card__message">
                                            {c.message}
                                        </div>
                                    </div>
                                    <span className="comment-card__time"><Moment format='HH:mm DD/MM/YYYY'>{c.created_at}</Moment></span>
                                </div>
                    })}
                </div>
                <div className="input-group">
                    <div className='row w-100'>
                        <div className='col-10'>
                            <input type="text" className="form-control messageinput" name="message"
                                placeholder="Write a comment here..."  value={newComment} onChange={(e)=>{setNewComment(e.target.value)}} onKeyPress={(e)=>{handleKeyPress(e)}}/>
                        </div>
                        <div className='col-2 p-0'>
                            <div className="input-group-append">
                                {sending == false?
                                    <button type="submit" className="btn btn-primary comment_submit w-100" onClick={()=>{sendMessage()}}>&#10132;</button>
                                    :                        
                                    <div className="loadingspinner calendarloader mx-auto"></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
        </>
    );
}

export default Chat;

if (document.getElementsByClassName('chat').length > 0) {
    Array.from(document.getElementsByClassName('chat')).forEach(el => {
        var data = el.getAttribute('data');
        ReactDOM.render(<Chat data={data} />, el);
    });
}
