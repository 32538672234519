import React, {useLayoutEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import ChildSeatRow from './ChildSeatRow';

function ChildSeatWrapper(props) {
    const [childseats, setChildseats] = useState();
    const [labels, setLabels] = useState();

    useLayoutEffect(() => {
        let temp = JSON.parse(props.data);
        setChildseats(temp?.childseats??[]);
        setLabels(temp?.labels??[]);
    }, []);

    const addRow = () => {
        if (childseats.length < 6) {
            let temp = [...childseats, {childseat_age: getAvailableAge(), childseat_amount: 1}];
            setChildseats(temp);
        }
    }

    const checkAgeNotUsed = (age) => {
        return childseats.filter((cs) => {
            return cs.childseat_age == age
        }).length == 0;
    }

    const getAvailableAge = (originalage=0) => {
        let availableAge = originalage;
        for (let i = 5; i >= 0; i--) {
            if (checkAgeNotUsed(i)) {
                availableAge = i;
            }
        }
        return availableAge;
    }

    const changeAge = (age, index) => {
        if (age == '') {
            let temp = [...childseats];
            temp[index].childseat_age = "";
            setChildseats(temp);
        } else {
            if (checkAgeNotUsed(age)) {
                let temp = [...childseats];
                temp[index].childseat_age = age;
                setChildseats(temp);
            } else {
                let temp = [...childseats];
                temp[index].childseat_age = getAvailableAge(childseats[index].childseat_age);
                setChildseats(temp);
            }
        }
    }

    const changeAmount = (amount, index) => {
        let temp = [...childseats];
        temp[index].childseat_amount = amount;
        setChildseats(temp);
    }

    return (
        <>
            <div className="childseats">
                {labels && childseats && childseats.length > 0 && childseats.map((c, i)=>{
                    return <ChildSeatRow key={i} ageLabel={labels.age} amountLabel={labels.amount} childseats={childseats} setChildseats={setChildseats} index={i} changeAge={changeAge} changeAmount={changeAmount}/>;
                })}
            </div>
            {childseats && childseats.length < 6 && 
                <div>
                    <button type='button' className='childseatAdd' onClick={()=>{addRow()}}>{labels?.addChildseat??'add'}</button>
                </div>
            }
        </>
    );
}

export default ChildSeatWrapper;

if (document.getElementsByClassName('childseatwrapper').length > 0) {
    Array.from(document.getElementsByClassName('childseatwrapper')).forEach(el => {
        var data = el.getAttribute('data');
        ReactDOM.render(<ChildSeatWrapper data={data} />, el);
    });
}
