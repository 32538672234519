import React, {useLayoutEffect, useState} from 'react';
import ReactDOM from 'react-dom';

function StopOverWrapper(props) {
    const [transport, setTransport] = useState();
    const [stopOvers, setStopOvers] = useState();
    const [deletedStopOvers, setDeletedStopOvers] = useState([]);
    const [labels, setLabels] = useState();

    useLayoutEffect(()=>{
        let temp = JSON.parse(props.data);
        setTransport(temp?.transport??[]);
        setStopOvers(temp?.stopOvers??[]);
        setLabels(temp?.labels??[]);
    }, []);

    const addRow = () => {
        let temp = [...stopOvers, {id:null, stopover_name:'', street:'', street_nr:'', city:'', zip:''}];
        setStopOvers(temp);
    }
    
    const changeField = (key, field, value) => {
        let temp = [...stopOvers];
        temp[key][field] = value;
        setStopOvers(temp);
    }

    const moveStopOver = (currentIndex, direction) => {
        let new_index = direction=='up'?currentIndex-1:currentIndex+1;
        let temp = [...stopOvers];

        if (new_index >= temp.length) {
            var k = new_index - temp.length + 1;
            while (k--) {
                temp.push(undefined);
            }
        }
        temp.splice(new_index, 0, temp.splice(currentIndex, 1)[0]);
        setStopOvers(temp);
    };

    const removeStopOver = (i) => {
        if (stopOvers[i].id != null) {
            setDeletedStopOvers([...deletedStopOvers, stopOvers[i].id]);
        }
        let temp = [...stopOvers]; 
        temp.splice(i, 1);
        setStopOvers(temp);
    }

    return (
        <>
        {labels && stopOvers &&
            <div className="stopovers">
                {deletedStopOvers && deletedStopOvers.length > 0 && deletedStopOvers.map((s)=>{
                    return <input type="hidden" name={"deletestopovers[]"} value={s}/>
                })}
                { stopOvers.length > 0 && stopOvers.map((stopover, i)=>{
                    return <div className='row stopoverrow' key={'stopover-' +i}>
                        <div className={stopOvers.length > 1 ? "col-10": "col-11"}>
                            <input type="hidden" name={"stopovers[" + i + "][id]"} value={stopover.id??''}/>
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor={'stopover_name-' + i}>{labels.stopover_name??'stopover_name'}</label>
                                    <input type="text" name={"stopovers[" + i + "][stopover_name]"} id={'stopover_name-' + i} className="form-control" value={stopover.stopover_name} onChange={(e)=>{changeField(i, 'stopover_name', e.currentTarget.value)}}/>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor={'street-' + i}>{labels.street??'street'}</label>
                                    <input type="text" required name={"stopovers[" + i + "][street]"} id={'street-' + i} className="form-control" value={stopover.street} onChange={(e)=>{changeField(i, 'street', e.currentTarget.value)}}/>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor={'street_nr-' + i}>{labels.street_nr??'street_nr'}</label>
                                    <input type="text" required name={"stopovers[" + i + "][street_nr]"} id={'street_nr-' + i} className="form-control" value={stopover.street_nr} onChange={(e)=>{changeField(i, 'street_nr', e.currentTarget.value)}}/>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor={'zip-' + i}>{labels.zip??'zip'}</label>
                                    <input type="text" required name={"stopovers[" + i + "][zip]"} id={'zip-' + i} className="form-control" value={stopover.zip} onChange={(e)=>{changeField(i, 'zip', e.currentTarget.value)}}/>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor={'city-' + i}>{labels.city??'city'}</label>
                                    <input type="text" required name={"stopovers[" + i + "][city]"} id={'city-' + i} className="form-control" value={stopover.city} onChange={(e)=>{changeField(i, 'city', e.currentTarget.value)}}/>
                                </div>
                            </div>
                        </div>
                        {stopOvers.length > 1 && 
                            <div className="col-1 d-flex">
                                <div className="m-auto d-flex flex-column justify-sapce-around stopovers-order">
                                    {i > 0 && <div onClick={()=>{moveStopOver(i,'up')}} role="button"><i className="stopovers-order__arrow mdi mdi-arrow-up-thick"></i></div>}
                                    {i < stopOvers.length-1 && <div onClick={()=>{moveStopOver(i,'down')}} role="button"><i className="stopovers-order__arrow mdi mdi-arrow-down-thick"></i></div>}
                                </div>
                            </div>
                        }
                        <div className="col-1 d-flex">
                            <div className="m-auto">
                                <button type="button" className="btn btn-danger childseatDelete p-0 px-md-2" onClick={()=>{removeStopOver(i)}}>&times;</button>
                            </div>
                        </div>
                        {i < stopOvers.length-1 && <div className="col-12"><hr /></div>}
                    </div>;
                })}
                <div className={stopOvers.length> 0? 'd-flex mt-3':'d-flex'}>
                    <button type='button' className='stopOversAdd mx-auto' onClick={()=>{addRow()}}>{labels?.addstopover??'add'}</button>
                </div>
            </div>
            }
        </>
    );
}

export default StopOverWrapper;

if (document.getElementsByClassName('stopoverwrapper').length > 0) {
    Array.from(document.getElementsByClassName('stopoverwrapper')).forEach(el => {
        var data = el.getAttribute('data');
        ReactDOM.render(<StopOverWrapper data={data} />, el);
    });
}
