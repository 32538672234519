import React, { useState } from 'react';

function ReservationProgressBar({labels, page=0, handleItemClick=()=>{}}) {
    const [items] = useState(labels);

    return (
        <div className="progressrow">
            {items && items.map((item, i)=>{
                if (item) {
                    return <div className={`progressrow-item ${page == i?'active': ''}`} key={i} onClick={()=>{handleItemClick(i)}}>
                                <span className="progressrow-item__text">{item}</span>
                            </div>
                }
            })}
        </div>
    );
}

export default ReservationProgressBar;

