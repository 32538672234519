const calcPrice = (
    departure_date,
    return_date,
    departure_destination,
    return_destination,
    client_address,
    second_address,
    couponcode,
    departure_stopovers,
    return_stopovers,
    departure_share_type,
    return_share_type,
    departure_place_id,
    return_place_id,
    Bac = false,
) =>{
    const myHeaders = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "X-CSRF-TOKEN" : document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }

    const options = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
            departure_date, 
            return_date, 
            departure_destination, 
            return_destination, 
            client_address, 
            second_address, 
            couponcode, 
            departure_stopovers, 
            return_stopovers, 
            departure_share_type, 
            return_share_type,
            departure_place_id,
            return_place_id,
            Bac,
        }),
        redirect: 'follow'
    };

    return fetch(`/api/website/calcprice`, options);
}

const createOrder = async (data) =>{
    const myHeaders = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "X-CSRF-TOKEN" : document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }
    
    const options = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
            wantsDepartureTransfer: data.wantsDepartureTransfer,
            wantsReturnTransfer: data.wantsReturnTransfer,
            po: data.po,
            travelagent_name: data.travelagent_name,
            departure_airport: data.departureAirport,
            departure_date: (data.wantsDepartureTransfer ? data.departureDate + ' ' + data.departureTime : null),
            departure_flight: data.departureFlight,
            checkin: parseFloat(data.checkin) * 60,
            return_airport: data.returnAirport,
            return_date: (data.wantsReturnTransfer ? data.returnDate + ' ' + data.returnTime : null),
            return_flight: data.returnFlight,
            destination_airport: data.destination,
            firstname: data.firstname,
            name: data.name,
            user_language: data.language,
            email: data.email,
            phone: data.phone,
            departure_address: data.full_address,
            departure_place_id: data.departurePlaceId,
            departure_share_type: data.departureShared,
            wants_same_facturation_address: data.wantsSameFacturationAddress,
            facturation_company_name: data.facturation_company_name,
            facturation_vat: data.facturation_vat,
            facturation_street: data.facturation_street,
            facturation_street_nr: data.facturation_street_nr + (data.facturation_street_box? ' ' + data.facturation_street_box : ''),
            facturation_city: data.facturation_city,
            facturation_zip: data.facturation_zip,
            facturation_country: data.facturation_country,
            suitcases: data.suitcases,
            wantsads: data.wantsads,
            amount_adults: parseInt(data.adults),
            amount_children: parseInt(data.children121),
            amount_infants: parseInt(data.infants),
            cargo_names: [
                'bikecases',
                'babycarriages',
                'wheelchairs',
                'skies',
                'rollators',
                'golfbags',
            ],
            cargo_amounts: [
                data.bikecases,
                data.babycarriages,
                data.wheelchairs,
                data.skies,
                data.rollators,
                data.golfbags,
            ],
            comments: data.comments,
            couponcode: data.couponcode,
            return_dropoff_address: data.dropoff_full_address,
            return_dropoff_place_id: data.returnPlaceId,
            return_share_type: data.returnShared,
            departure_stopovers: data.departure_stopovers,
            return_stopovers: data.return_stopovers,
            facturation_target: data.facturation_target,
            hotel_id: data.hotel_id,
            tuiVip: data.tuiVip,
            Bac: data.Bac,
        }),
        redirect: 'follow'
    };

    let resp = await fetch(`/api/website/orderrequest/store`, options);
    return resp;
}

const checkPayment = async (uuid) =>{
    const myHeaders = {
        "X-CSRF-TOKEN" : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }

    const options = {
        method: 'GET',
        headers: myHeaders
    };

    let resp = await fetch(`/api/website/payment/${uuid}`, options);
    return resp;
}

const retryPayment = async (oldPaymentUuid, tuiVip = false) =>{
    const myHeaders = {
        "X-CSRF-TOKEN" : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }

    const options = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    let resp = await fetch(`/api/website/payment`+(tuiVip? '/tui-vip' : '')+`/${oldPaymentUuid}/retry`, options);
    return resp;
}

function getCurrentDate(){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}-${month<10?`0${month}`:`${month}`}-${date<10?`0${date}`:`${date}`}`
}

function getNextDate(oldDate, daystoadd=1){
    let newDate = new Date(oldDate)
    newDate.setDate(newDate.getDate() + daystoadd);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}-${month<10?`0${month}`:`${month}`}-${date<10?`0${date}`:`${date}`}`
}

function diffInDays(date1, date2){
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays;
}

const capitalizeFirstLetter = (string) => {
    if(!!string){
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return '';
}

const checkPlaceId = async (place_id) => {
    const myHeaders = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "X-CSRF-TOKEN" : document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }


    const options = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
            place_id,
        }),
        redirect: 'follow'
    };

    let resp = await fetch(`/api/website/checkAddress`, options);

    return await resp.json();
}

export {
    calcPrice,
    checkPayment,
    createOrder,
    diffInDays,
    getCurrentDate,
    getNextDate,
    retryPayment,
    capitalizeFirstLetter,
    checkPlaceId
}