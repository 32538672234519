import React, { useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';

function PricingWrapper(props) {
    const [prefix, setPrefix] = useState();
    const [prices, setPrices] = useState();
    const [labels, setLabels] = useState();

    useLayoutEffect(() => {
        let temp = JSON.parse(props.data);

        setPrices(temp?.prices ?? [{
            km_start: 0,
            km_end: '',
            default_price: '',
            extra_price_per_km: '',
            default_price_departure: '',
            default_price_return: '',
            default_price_stop_over: '',
        }]);
        setLabels(temp?.labels ?? []);
        setPrefix(temp?.prefix ?? '');
    }, []);

    const addRow = () => {
        let temp = [...prices, {
            km_start: prices.length > 0 ? prices[prices.length - 1].km_end : 0,
            km_end: '',
            default_price: '',
            extra_price_per_km: '',
            default_price_departure: '',
            default_price_return: '',
            default_price_stop_over: '',
        }];
        console.log(temp);
        setPrices(temp);
    }

    const changeDefaultPrice = (value, index) => {
        let temp = [...prices];
        temp[index].default_price = value;
        setPrices(temp);
    }

    const changeDefaultPriceStopOver = (value, index) => {
        let temp = [...prices];
        temp[index].default_price_stop_over = value;
        setPrices(temp);
    }

    const changeDefaultPriceDeparture = (value, index) => {
        let temp = [...prices];
        temp[index].default_price_departure = value;
        setPrices(temp);
    }

    const changeDefaultPriceReturn = (value, index) => {
        let temp = [...prices];
        temp[index].default_price_return = value;
        setPrices(temp);
    }

    const changeExtraPricePerKm = (value, index) => {
        let temp = [...prices];
        temp[index].extra_price_per_km = value;
        setPrices(temp);
    }

    const changeKmStart = (value, index) => {
        let temp = [...prices];
        temp[index].km_start = value;
        if (index > 0) {
            temp[index - 1].km_end = value;
        }
        setPrices(temp);
    }

    const blurKmStart = () => {
        let temp = [...prices];
        setPrices(reformat(temp));
    }

    const changeKmEnd = (value, index) => {
        let temp = [...prices];
        temp[index].km_end = value;
        if (index < prices.length - 1) {
            temp[index + 1].km_start = value;
        }
        setPrices(temp);
    }

    const blurKmEnd = () => {
        let temp = [...prices];
        setPrices(reformat(temp));
    }

    const removeRow = (index) => {
        let temp = [...prices];
        temp.splice(index, 1);
        setPrices(reformat(temp));
    }

    const reformat = (distancesArray) => {
        let temp = [];
        distancesArray.forEach((d, i) => {
            let kmStart = parseFloat((i > 0 ? temp[i - 1].km_end : d.km_start));
            let kmEnd = parseFloat(d.km_end);
            let parsedKmStart = isNaN(kmStart) ? 0 : kmStart;
            let parsedKmEnd = isNaN(kmEnd) ? 0 : kmEnd;

            if ((parsedKmEnd < parsedKmStart && i == distancesArray.length - 1 && !isNaN(kmEnd)) || (parsedKmEnd < parsedKmStart && i != distancesArray.length - 1)) {
                kmEnd = kmStart;
            }
            temp.push({ ...d, km_start: kmStart, km_end: kmEnd });
        });
        return temp;
    }

    return (
        <div className='row mb-3'>
            <div className="col-12 mb-3">
                {labels && prices && prices.length > 0 && prices.map((c, i) => {
                    return <div className="row mb-3" key={i}>
                        <div className='col-10'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label className='d-block text-truncate'>{labels.km_start ?? 'km_start'}</label>
                                            <div className="input-group">
                                                <input type="number" min={0} value={prices[i].km_start} onChange={(e) => { changeKmStart(e.target.value, i); }} onBlur={(e) => { blurKmStart(e.target.value, i) }} className="form-control" name={(prefix != '' ? prefix + '_' : '') + "km_start[]"} required={true} />
                                                <div className="percentageLabel input-group-append text-center">
                                                    <span className="input-group-text rounded-0 rounded-end h-100">km</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label className='d-block text-truncate'>{labels.km_end ?? 'km_end'}</label>
                                            <div className="input-group">
                                                <input type="number" min={0} value={prices[i].km_end} onChange={(e) => { changeKmEnd(e.target.value, i); }} onBlur={(e) => { blurKmEnd(e.target.value, i) }} className="form-control" name={(prefix != '' ? prefix + '_' : '') + "km_end[]"} required={prices.length - 1 != i} />
                                                <div className="percentageLabel input-group-append text-center">
                                                    <span className="input-group-text rounded-0 rounded-end h-100">km</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-3 mb-3 mb-md-0'>
                                    <label className='d-block text-truncate'>{labels.default_price ?? 'default_price'}</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend euroLabel" >
                                            <span className="input-group-text rounded-0 rounded-start h-100" id="price">€</span>
                                        </div>
                                        <input type="number" value={prices[i].default_price} onChange={(e) => { changeDefaultPrice(e.target.value, i); }} className="form-control" name={(prefix != '' ? prefix + '_' : '') + "default_price[]"} step="0.01" />
                                    </div>
                                </div>
                                <div className='col-md-3 mb-3 mb-md-0'>
                                    <label className='d-block text-truncate'>{labels.extra_price_per_km ?? 'extra_price_per_km'}</label>
                                    <div className="input-group">
                                        <input type="number" value={prices[i].extra_price_per_km} onChange={(e) => { changeExtraPricePerKm(e.target.value, i); }} className="form-control" name={(prefix != '' ? prefix + '_' : '') + "extra_price_per_km[]"} step="0.01" />
                                        <div className="percentageLabel input-group-append text-center">
                                            <span className="input-group-text rounded-0 rounded-end h-100">€/km</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3 my-3 mb-md-0'>
                                    <label className='d-block text-truncate'>{labels.default_price_departure ?? 'default_price_departure'}</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend euroLabel" >
                                            <span className="input-group-text rounded-0 rounded-start h-100" id="price">€</span>
                                        </div>
                                        <input type="number" value={prices[i].default_price_departure} onChange={(e) => { changeDefaultPriceDeparture(e.target.value, i); }} className="form-control" name={(prefix != '' ? prefix + '_' : '') + "default_price_departure[]"} />
                                    </div>
                                </div>
                                <div className='col-md-3 my-3 mb-md-0'>
                                    <label className='d-block text-truncate'>{labels.default_price_return ?? 'default_price_return'}</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend euroLabel" >
                                            <span className="input-group-text rounded-0 rounded-start h-100" id="price">€</span>
                                        </div>
                                        <input type="number" value={prices[i].default_price_return} onChange={(e) => { changeDefaultPriceReturn(e.target.value, i); }} className="form-control" name={(prefix != '' ? prefix + '_' : '') + "default_price_return[]"} />
                                    </div>
                                </div>
                                <div className='col-md-3 my-3 mb-md-0'>
                                    <label className='d-block text-truncate'>{labels.default_price_stop_over ?? 'default_price_stop_over'}</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend euroLabel" >
                                            <span className="input-group-text rounded-0 rounded-start h-100" id="price">€</span>
                                        </div>
                                        <input type="number" value={prices[i].default_price_stop_over} onChange={(e) => { changeDefaultPriceStopOver(e.target.value, i); }} className="form-control" name={(prefix != '' ? prefix + '_' : '') + "default_price_stop_over[]"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-2 p-0 d-flex justify-content-center align-items-center">
                            {(i > 0 || prefix != '') &&
                                <button type="button" className="btn btn-danger distancesDelete p-0 px-md-2" onClick={() => { removeRow(i); }}>
                                    &times;
                                </button>
                            }
                        </div>
                    </div>
                })}
            </div>
            <div className='col-auto mx-auto'>
                <button type='button' className='col-12 distancesAdd' onClick={() => { addRow() }}>{labels?.adddistances ?? 'add'}</button>
            </div>

        </div>
    );
}

export default PricingWrapper;

if (document.getElementsByClassName('PricingWrapper').length > 0) {
    Array.from(document.getElementsByClassName('PricingWrapper')).forEach(el => {
        var data = el.getAttribute('data');
        ReactDOM.render(<PricingWrapper data={data} />, el);
    });
}
