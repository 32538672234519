import React, { useState, useRef, useEffect } from 'react';
import useClickOutside from '../utilities/useClickOutside';
import { capitalizeFirstLetter } from '../utilities/Utilities';

let timer;

const AutoCompleteInput = ({value='', setValue=()=>{}, onRowClick=()=>{}, labels={}, errors=[], errorIds=[], extraClasses=""}) => {
    const [showPredictions, setShowPredictions] = useState(false);
    const [predictions, setPredictions] = useState([]);
    const [loading, setLoading] = useState(false);
    const wrapperRef = useRef();

    useClickOutside(wrapperRef, () => {
        setShowPredictions(false);
    })

    useEffect(() => {
        let isMounted = true;

        if(value.length > 0)
        {
            if(isMounted) {
                initialize(value);
            }               
        }

        return () => {
            isMounted = false;
          };
    }, [])

    const initialize = async (value) => {
        getPredictions(value).then(content => {
            if(content.status == 'OK'){
                const topPrediction = content.predictions[0];
                handleRowClick(topPrediction.description, topPrediction.place_id);
            }
        });
    };

    const getPredictions = async (value) => {
        const rawResponse = await fetch("/autocomplete", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "X-CSRF-TOKEN" : document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            },
            body: JSON.stringify({
                text: value
            })
        });
        return await rawResponse.json();
        
        
    }

    const handleKeyUp = (value) => {
        setLoading(true);
        clearTimeout(timer);
        timer = setTimeout(async () => {
            const content = await getPredictions(value);

            setLoading(false);
            if(content.status == 'OK'){
                setPredictions(content.predictions);
                setShowPredictions(true);
            } else {
                setPredictions([]);
            }
        }, 1000);
    }


    const handleRowClick = (text, place_id) => {
        let temp = setValue(text);
        setShowPredictions(false);
        onRowClick(place_id, temp);
    }

    return (
        <div className='position-relative w-100' ref={wrapperRef}>
            <input type="text" onKeyUp={(e) => handleKeyUp(e.target.value)} value={value} placeholder={capitalizeFirstLetter(labels.address)} onChange={(e)=>{setValue(e.currentTarget.value)}} onFocus={()=>{setShowPredictions(true)}} onClick={(e)=>{e.currentTarget.select()}} className={((loading || (showPredictions && predictions.length > 0)) ? 'active ' : '') + "mb-0 autocomplete-input" + (errors.some(r=> errorIds.includes(r))?' is-invalid':'') + ' ' + extraClasses}  />
            {(loading || (showPredictions && predictions.length > 0)) && 
                <div className="autocomplete-wrapper autocomplete-wrapper__inline">
                    {loading && 
                        <div className="autocomplete-row">
                            <span className="autocomplete-row_description">loading...</span>
                        </div>
                    }
                    {showPredictions && !loading && predictions.map((prediction, i) => {
                        return <div className="autocomplete-row" onClick={(e)=>{handleRowClick(prediction.description, prediction.place_id)}} key={'autocomplete-row-'+i}>
                                    <span className="autocomplete-row_description">{prediction.description}</span>
                                    {prediction.structured_formatting.secondary_text && <span className="autocomplete-row__secondarytext">{prediction.structured_formatting.secondary_text}</span>}
                                </div>
                    })}
                </div>
            }
        </div>
    );
}

export default AutoCompleteInput;
