import flatpickr from 'flatpickr';

import './bootstrap';

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import './components/AutoCompleteInput.jsx';
import './components/ReservationPageStopoverWrapper.jsx';
import './components/ReservationProgressBar.jsx';
import './components/Searchbar.jsx';
import './components/CargoRow.jsx';
import './components/CargoWrapper.jsx';
import './components/ChildSeatRow.jsx';
import './components/ChildSeatWrapper.jsx';
import './components/Chat.jsx';
import './components/DiscountCodeDisctanceWrapper.jsx';
import './components/FeedbackStars.jsx';
import './components/ReservationPage.jsx';
import './components/CheckPayment.jsx';
import './components/StopOverWrapper.jsx';
import './components/PricingWrapper.jsx';

window.flatpickr = flatpickr;