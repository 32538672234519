import React, {useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { calcPrice, createOrder, diffInDays, capitalizeFirstLetter, checkPlaceId } from '../utilities/Utilities';
import ReservationProgressBar from './ReservationProgressBar';
import AutoCompleteInput from './AutoCompleteInput';
import InputMask from 'react-input-mask';
import useWindowDimensions from '../utilities/useWindowDimensions';
import moment from 'moment';

let flightTimer;
let pricesTimer;
let couponTimer;

function ReservationPage(props) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const { height, width } = useWindowDimensions();
    const passengerErrors = ['adults', 'children121', 'infants'];
    const addressErrors = ['full_address'];
    const dropoffAddressErrors = ['dropoff_full_address'];
    const cargoVariables = ['suitcases','bikecases','babycarriages','wheelchairs','skies','rollators','golfbags'];
    const facturationCountries = [
        'AF',
        'AL',
        'DZ',
        'AS',
        'AD',
        'AO',
        'AI',
        'AQ',
        'AG',
        'AR',
        'AM',
        'AW',
        'AU',
        'AT',
        'AZ',
        'BS',
        'BH',
        'BD',
        'BB',
        'BY',
        'BE',
        'BZ',
        'BJ',
        'BM',
        'BT',
        'BO',
        'BQ',
        'BA',
        'BW',
        'BV',
        'BR',
        'IO',
        'BN',
        'BG',
        'BF',
        'BI',
        'KH',
        'CM',
        'CA',
        'CV',
        'KY',
        'CF',
        'TD',
        'CL',
        'CN',
        'CX',
        'CC',
        'CO',
        'KM',
        'CG',
        'CD',
        'CK',
        'CR',
        'CI',
        'HR',
        'CU',
        'CW',
        'CY',
        'CZ',
        'DK',
        'DJ',
        'DM',
        'DO',
        'EC',
        'EG',
        'SV',
        'GQ',
        'ER',
        'EE',
        'SZ',
        'ET',
        'FK',
        'FO',
        'FJ',
        'FI',
        'FR',
        'GF',
        'PF',
        'TF',
        'GA',
        'GM',
        'GE',
        'DE',
        'GH',
        'GI',
        'GR',
        'GL',
        'GD',
        'GP',
        'GU',
        'GT',
        'GG',
        'GN',
        'GW',
        'GY',
        'HT',
        'HM',
        'VA',
        'HN',
        'HK',
        'HU',
        'IS',
        'IN',
        'ID',
        'IR',
        'IQ',
        'IE',
        'IM',
        'IL',
        'IT',
        'CI',
        'JM',
        'JP',
        'JE',
        'JO',
        'KZ',
        'KE',
        'KI',
        'KP',
        'KR',
        'KW',
        'KG',
        'LA',
        'LA',
        'LV',
        'LB',
        'LS',
        'LR',
        'LY',
        'LI',
        'LT',
        'LU',
        'MO',
        'MK',
        'MG',
        'MW',
        'MY',
        'MV',
        'ML',
        'MT',
        'MH',
        'MQ',
        'MR',
        'MU',
        'YT',
        'MX',
        'FM',
        'MD',
        'MC',
        'MN',
        'ME',
        'MS',
        'MA',
        'MZ',
        'MM',
        'NA',
        'NR',
        'NP',
        'NL',
        'AN',
        'NC',
        'NZ',
        'NI',
        'NE',
        'NG',
        'NU',
        'NF',
        'MP',
        'NO',
        'OM',
        'PK',
        'PW',
        'PS',
        'PA',
        'PG',
        'PY',
        'PE',
        'PH',
        'PN',
        'PL',
        'PT',
        'PR',
        'QA',
        'RW',
        'RE',
        'RO',
        'RU',
        'BL',
        'SH',
        'KN',
        'LC',
        'MF',
        'PM',
        'VC',
        'WS',
        'SM',
        'ST',
        'SA',
        'SN',
        'RS',
        'SC',
        'SL',
        'SG',
        'SX',
        'SK',
        'SI',
        'SB',
        'SO',
        'ZA',
        'GS',
        'SS',
        'ES',
        'LK',
        'SD',
        'SR',
        'SJ',
        'SZ',
        'SE',
        'CH',
        'SY',
        'TW',
        'TJ',
        'TZ',
        'TH',
        'TL',
        'TG',
        'TK',
        'TO',
        'TT',
        'TN',
        'TR',
        'TM',
        'TC',
        'TV',
        'UG',
        'UA',
        'AE',
        'GB',
        'US',
        'UM',
        'UY',
        'UZ',
        'VU',
        'VE',
        'VN',
        'VN',
        'VG',
        'VI',
        'WF',
        'EH',
        'YE',
        'ZM',
        'ZW',
        'AX',
    ];

    const [showTicket, setShowTicket] = useState(false);
    const [countries, setCountries] = useState([]);
    const [destinations, setDestinations] = useState();
    const [labels, setLabels] = useState();
    const [isTravelAgent, setIsTravelAgent] = useState(false);
    const [isHotel, setIsHotel] = useState(false);
    const [shouldPayImmediately, setShouldPayImmediately] = useState(true);
    const [tuiVip, setTuiVip] = useState(false);
    const [requiredInputsperPage, setRequiredInputsperPage] = useState();
    const [shareDiscountSetting, setShareDiscountSetting] = useState();
    
    const [departureDiscount, setDepartureDiscount] = useState();
    const [returnDiscount, setReturnDiscount] = useState();
    const [departureDiscountShared, setDepartureDiscountShared] = useState();
    const [returnDiscountShared, setReturnDiscountShared] = useState();
    const [flatDiscount, setFlatDiscount] = useState();
    const [creatingOrder, setCreatingOrder] = useState(false);
    const [validAddress, setValidAddress] = useState(false);
    const [minDate, setMinDate] = useState();
    const [minReturnDate, setMinReturnDate] = useState();

    const [form, setForm] = useState({});

    const [departurePrice, setDeparturePrice] = useState(0);
    const [returnPrice, setReturnPrice] = useState(0);
    const [departurePriceShared, setDeparturePriceShared] = useState(0);
    const [returnPriceShared, setReturnPriceShared] = useState(0);
    const [cargo, setCargo] = useState('');
    const [childseats, setChildseats] = useState('');
    const [departurePlaceId, setDeparturePlaceId] = useState();
    const [returnPlaceId, setReturnPlaceId] = useState();

    const [loadingPrice, setLoadingPrice] = useState(false);
    const [page, setPage] = useState(0);
    const [inputErrors, setInputErrors] = useState([]);
    const [submitErrors, setSubmitErrors] = useState({});
    const [validCode, setValidCode] = useState(true);
    const [lastvalidatedCode, setLastvalidatedCode] = useState('');
    const [stopoverErrors, setStopoverErrors] = useState({});
    const [rowClicked, setRowClicked] = useState(false);
    const [departurePlaceIdError, setDeparturePlaceIdError] = useState();
    const [returnPlaceIdError, setReturnPlaceIdError] = useState();

    useEffect(()=>{
        if (isTravelAgent) {
            setRequiredInputsperPage([
                ['po', 'travelagent_name', 'adults', 'full_address', 'departureDate', 'departureAirport', 'departureFlight', 'departureTime', 'checkin', 'returnDate', 'returnAirport', 'returnFlight', 'returnTime', 'adults', 'suitcases', 'bikecases', 'babycarriages', 'wheelchairs', 'skies', 'rollators', 'golfbags', 'children121', 'infants', 'departureRowClicked', 'returnRowClicked', 'destination'],
                ['firstname', 'name', 'email', 'phone', 'acceptprivacypolicy', 'acceptplaceorder'],
            ]);
        } else {
            setRequiredInputsperPage([
                ['adults', 'full_address', 'departureDate', 'departureAirport', 'departureFlight', 'departureTime', 'checkin', 'returnDate', 'returnAirport', 'returnFlight', 'returnTime', 'adults', 'suitcases', 'bikecases', 'babycarriages', 'wheelchairs', 'skies', 'rollators', 'golfbags', 'children121', 'infants', 'departureRowClicked', 'returnRowClicked', 'destination'],
                ['firstname', 'name', 'email', 'phone', 'acceptprivacypolicy'],
            ]);
        }
    }, [isTravelAgent]);

    useEffect(()=>{
        if (requiredInputsperPage) {
            let temp = [...requiredInputsperPage];
            if (!form.wantsSameFacturationAddress) {
                temp[1].push('facturation_street', 'facturation_street_nr', 'facturation_zip', 'facturation_city', 'facturation_country');
            } else {
                temp[1] = temp[1].filter((input)=> {return !['facturation_street', 'facturation_street_nr', 'facturation_zip', 'facturation_city', 'facturation_country'].includes(input);} )
            }
            setRequiredInputsperPage(temp);
        }
    }, [form.wantsSameFacturationAddress])

    useEffect(()=>{
        if (requiredInputsperPage) {
            let temp = [...requiredInputsperPage];
            if ((!form.departureFlowFromAirport || (!form.returnFlowFromAirport && form.transferType == 'with_retour'))) {
                temp[0].push('departureDate', 'departureAirport', 'departureFlight', 'departureTime', 'full_address', 'departureRowClicked');
            } else {
                temp[0] = temp[0].filter((input)=> {return !['departureDate', 'departureAirport', 'departureFlight', 'departureTime', 'full_address', 'departureRowClicked'].includes(input);} )
            }
            if ((form.departureFlowFromAirport || (form.returnFlowFromAirport && form.transferType == 'with_retour'))) {
                temp[0].push('returnDate', 'returnAirport', 'returnFlight', 'returnTime');
            } else {
                temp[0] = temp[0].filter((input)=> {return !['returnDate', 'returnAirport', 'returnFlight', 'returnTime'].includes(input);} )
            }
            setRequiredInputsperPage(temp);
        }

    }, [form.wantsDepartureTransfer, form.wantsReturnTransfer]);

    useEffect(()=>{
        if (form.couponcode) {
            setValidCode(true);
        }
    }, [form.couponcode]);

    useEffect(()=>{
        if (document.querySelectorAll('.progressrow')[0]) {
            document.querySelectorAll('.progressrow')[0]?.scrollIntoView({behavior: "smooth", block: "end", inline: "center"});
            document.querySelectorAll('input[type="text"]')[0]?.focus();
        }
    }, [page]);
    
    useEffect(()=>{
        if (inputErrors.length > 0) {
            document.querySelectorAll('.is-invalid')[0]?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
            document.querySelectorAll('.is-invalid')[0]?.focus();
        }
    }, [inputErrors]);

    useEffect(()=>{
        if (Number.isInteger(parseInt(form.adults)) && !checkAmountOfPeople()) {
            setInputErrors([...passengerErrors, ...cargoVariables]);
        } else {
            let temp = [...inputErrors];
            setInputErrors(temp.filter((err)=>{
                return !passengerErrors.includes(err);
            }));
        }
    },[form.adults, form.children121, form.infants]);

    useEffect(()=>{
        if (!checkAmountOfCargo()) {
            addCargoErrors();
        } else {
            let temp = [...inputErrors];
            setInputErrors(temp.filter((err)=>{
                return !cargoVariables.includes(err);
            }));
        }
    },[form.suitcases, form.bikecases, form.babycarriages, form.wheelchairs, form.skies, form.rollators, form.golfbags]);

    useEffect(()=>{
        let temp = JSON.parse(props.data);

        setShareDiscountSetting(temp.shareDiscountSetting);
        setDestinations(temp?.destinations??[]);
        setLabels(temp?.labels??[]);
        setCountries(temp?.countries??[]);
        setIsHotel(temp?.isHotel??false);
        setIsTravelAgent(temp?.isTravelAgent??false);
        setShouldPayImmediately(temp?.shouldPayImmediately??true);        
        setTuiVip(temp?.TuiVip??false);
        let mindate = temp?.minReservationDate.split('T')[0];
        setMinDate(mindate);
        setMinReturnDate(mindate);
        setDeparturePlaceId(urlParams.get('place_id')?.length > 0 ? urlParams.get('place_id') :'');
        setReturnPlaceId(urlParams.get('place_id')?.length > 0 ? urlParams.get('place_id') :'');

        let address;
        if(urlParams.get('address')?.length > 0)
        {
            address = urlParams.get('address');            
        } else {
            address = urlParams.get('full_address')?.length > 0 ? urlParams.get('full_address') : (temp?.hotel_id?(temp?.hotel_street + ' ' . temp?.hotel_street_nr + ', ' + temp?.hotel_zip + ' ' + temp?.hotel_city):'');
        }

        let departureDate;
        let returnDate;

        if (urlParams.get('flightDateInbound')?.length > 0)
        {
            returnDate = urlParams.get('flightDateInbound').slice(4) + '-' + urlParams.get('flightDateInbound').slice(2, 4) + '-' + urlParams.get('flightDateInbound').slice(0, 2); 
        }
        if (urlParams.get('flightDateOutbound')?.length > 0) 
        {
            departureDate = urlParams.get('flightDateOutbound').slice(4) + '-' + urlParams.get('flightDateOutbound').slice(2, 4) + '-' + urlParams.get('flightDateOutbound').slice(0, 2);            
        } else if(!(urlParams.get('flightDateInbound')?.length > 0))
        {
            const defaultDate =  moment(mindate.split('T')[0]).add(5, 'w').format('Y-MM-DD');

            departureDate = (urlParams.get('departure_date')?.length > 0 && urlParams.get('departure_date') >= mindate && (urlParams.get('transferType')?.length > 0 && urlParams.get('transferType') != 'single' || urlParams.get('single_type') == 'departure')) ? urlParams.get('departure_date') : defaultDate;
            returnDate = (urlParams.get('return_date')?.length > 0 && urlParams.get('return_date') >= mindate) ? urlParams.get('return_date') : defaultDate;
        }

        let departureAirport;
        let returnAirport;

        if (urlParams.get('airportInbound')?.length > 0)
        {
            returnAirport = urlParams.get('airportInbound'); 
        }
        if (urlParams.get('airportOutbound')?.length > 0) 
        {
            departureAirport = urlParams.get('airportOutbound');            
        } else if(!(urlParams.get('airportInbound')?.length > 0))
        {
            departureAirport = returnAirport = urlParams.get('airport')?.length > 0 ? urlParams.get('airport'):'BRU';
        }

        let departureFlight;
        let returnFlight;

        if (urlParams.get('flightNumberInbound')?.length > 0)
        {
            returnFlight = urlParams.get('flightNumberInbound'); 
        }
        if (urlParams.get('flightNumberOutbound')?.length > 0) 
        {
            departureFlight = urlParams.get('flightNumberOutbound');            
        } else if(!(urlParams.get('flightNumberInbound')?.length > 0))
        {
            returnFlight = departureFlight = 'TB0000';
        }

        let destination;

        if (urlParams.get('foreignAirportInbound')?.length > 0)
        {
            destination = urlParams.get('foreignAirportInbound'); 
        }
        if (urlParams.get('foreignAirportOutbound')?.length > 0) 
        {
            destination = urlParams.get('foreignAirportOutbound');            
        } else if(!(urlParams.get('foreignAirportInbound')?.length > 0))
        {
            destination = urlParams.get('foreignAirport')?.length > 0 ? urlParams.get('foreignAirport'):'';
        }
    
        let departureTime;
        let returnTime;
        if(urlParams.get('flightHourInbound')?.length > 0)
        {
            returnTime = urlParams.get('flightHourInbound').slice(0, 2) + ':' + urlParams.get('flightHourInbound').slice(2);
        }
        if(urlParams.get('flightHourOutbound')?.length > 0){ 
            departureTime = urlParams.get('flightHourOutbound').slice(0, 2) + ':' + urlParams.get('flightHourOutbound').slice(2);
            
        } else if(!(urlParams.get('flightHourInbound')?.length > 0)){
            departureTime = urlParams.get('departure_time')?.length > 0 ?urlParams.get('departure_time'):'00:00';
            returnTime = urlParams.get('return_time')?.length > 0 ?urlParams.get('return_time'):'00:00';
        }

        let language;
        if(urlParams.get('language')?.length > 0)
        {
            language = urlParams.get('language');
        } else {
            language = temp?.language??'en';
        }

        let adults;
        if(urlParams.get('amountOfPassengers')?.length > 0)
        {
            adults = urlParams.get('amountOfPassengers');
        } else {
            adults = urlParams.get('adults')?.length > 0 ? urlParams.get('adults') : 1;
        }

        let transferType;

        let departureFlowFromAirport = false;
        let returnFlowFromAirport = true;

        switch (true) {
            case ((urlParams.get('flightDateOutbound')?.length > 0) && (urlParams.get('flightDateInbound')?.length > 0)):
                transferType = 'with_retour';
                if(moment(returnDate + ' ' + returnTime).isBefore(moment(departureDate + ' ' + departureTime)))
                {
                    departureFlowFromAirport = true;
                    returnFlowFromAirport = false;
                }
                break;
                
            case (!(urlParams.get('flightDateOutbound')?.length > 0) && (urlParams.get('flightDateInbound')?.length > 0)):
                transferType = 'inbound';
                departureFlowFromAirport = true;
                returnFlowFromAirport = false;
                break;

            case ((urlParams.get('flightDateOutbound')?.length > 0) && !(urlParams.get('flightDateInbound')?.length > 0)):
                transferType = 'outbound';
                break;
            case (!(urlParams.get('flightDateOutbound')?.length > 0) && !(urlParams.get('flightDateInbound')?.length > 0)):
                if(urlParams.get('transferType') == 'single' && urlParams.get('single_type') == 'return')
                {
                    departureFlowFromAirport = true;
                    returnFlowFromAirport = false;
                }

                transferType = (urlParams.get('transferType')?.length > 0 ? urlParams.get('transferType') : 'with_retour');
                break;
        }

        let tempform = {
            transferType: transferType,
            adults: adults,
            children121: (urlParams.get('children')?.length > 0 ? urlParams.get('children') : 0),
            infants: (urlParams.get('infants')?.length > 0 ? urlParams.get('infants') : 0),
            departureRowClicked: (urlParams.get('place_id')?.length > 0),
            returnRowClicked: (urlParams.get('place_id')?.length > 0),
            po: '',
            travelagent_name: '',
            full_address: address,
            country: (temp.hotel_country !== ''?temp.hotel_country:'BE'),
            facturation_company_name: '',
            facturation_vat: '',
            facturation_street: '',
            facturation_street_nr: '',
            facturation_street_box: '',
            facturation_zip: '',
            facturation_city: '',
            facturation_country: 'BE',
            wantsSameFacturationAddress: !temp.isHotel,
            wantsDepartureTransfer: true,
            departureFlowFromAirport: departureFlowFromAirport,
            departureDate: departureDate,
            departureAirport: departureAirport,
            departureFlight: departureFlight,
            departureTime: departureTime,
            departureShared: (temp?.Bac??false) ? 'private' : 'shared',
            checkin: urlParams.get('checkin') > 0 ? urlParams.get('checkin') : 2,
            wantsReturnTransfer:transferType == 'with_retour',
            returnFlowFromAirport: returnFlowFromAirport,
            returnDate: returnDate,
            returnAirport: returnAirport,
            returnFlight: returnFlight,
            returnTime: returnTime,
            returnShared: (temp?.Bac??false) ? 'private' : 'shared',
            destination: destination,
            suitcases: parseInt(adults)?? 1  + parseInt(urlParams.get('children')??0),
            bikecases: 0,
            babycarriages: 0,
            wheelchairs: 0,
            skies: 0,
            rollators: 0,
            golfbags: 0,
            comments: '',
            firstname: '',
            name: '',
            email: '',
            language: language,
            facturation_target: 'travelagency',
            phone: '',
            acceptprivacypolicy: false,
            wantsads: false,
            acceptplaceorder: false,
            valid_address: false,
            couponcode: '',
            samedropoff: true,
            dropoff_full_address: address,
            dropoff_country: 'BE',
            departure_stopovers: [],
            return_stopovers: [],
            same_return_stopovers: true,
            hotel_id: temp.hotel_id,
            tuiVip: temp?.TuiVip??false,
            Bac: temp?.Bac??false,
            transferTypeFromQuery: (urlParams.get('flightDateInbound')?.length > 0 || (urlParams.get('flightDateOutbound')?.length > 0) ? true : false),
            minDate: mindate,
        };

        setTimeout(() => {
            setForm(tempform);
            changeFormData('phone', '');
        }, 100);
        let elements = document.getElementsByClassName('reservationmodule');
        for (let el of elements) {
            el.removeAttribute('data');
        }
    }, [props.data]);

    useEffect(() => {
        getPrices()
    }, [
        form.departureDate,
        form.departureTime,
        form.returnDate,
        form.returnTime,
        form.departureAirport,
        form.returnAirport,
        form.wantsDepartureTransfer,
        form.wantsReturnTransfer,
        rowClicked,
        form.couponcode,
        form.departureShared,
        form.returnShared,
    ]);
    
    useEffect(()=>{
        updateCargo();
    }, [
        form.suitcases,
        form.bikecases,
        form.babycarriages,
        form.wheelchairs,
        form.skies,
        form.rollators,
        form.golfbags,
    ]);
    
    useEffect(async ()=>{
        if(departurePlaceId){
            let data = await checkPlaceId(departurePlaceId);
            setDeparturePlaceIdError(data.error_message);
        }
    }, [
        departurePlaceId
    ]);

    useEffect(async ()=>{
        if(returnPlaceId){
            let data = await checkPlaceId(returnPlaceId);
            setReturnPlaceIdError(data.error_message);
        }
    }, [
        returnPlaceId
    ]);

    const mapStopOversToAddresses = (array) => {
        return array.map((s)=>{return `${s.street} ${s.street_nr}${s.street_box} ${s.zip} ${s.city}`})
    }

    const getPrices= ()=>{
        setLoadingPrice(true);
        clearTimeout(pricesTimer);
        pricesTimer = setTimeout(() => {
            setStopoverErrors({});

            const sendDepartureData = (!form.departureFlowFromAirport || (!form.returnFlowFromAirport && form.transferType == 'with_retour')) && form.full_address?.length > 0 && form.departureAirport?.length > 0 && form.departureDate?.length > 0 && form.departureTime?.length > 0;
            const sendReturnData = (form.departureFlowFromAirport || (form.returnFlowFromAirport && form.transferType == 'with_retour')) && form.dropoff_full_address?.length > 0 && form.returnDate?.length > 0 && form.returnTime?.length > 0 && form.returnAirport?.length > 0;

            if (form?.departure_stopovers?.filter(s => (s.street == '' || s.zip == '' || s.city == '')).length == 0 && form?.return_stopovers?.filter(s => (s.street == '' || s.zip == '' || s.city == '')).length == 0 && 
                (sendDepartureData ||
                sendReturnData ||
                (sendDepartureData && sendReturnData))) {
                const fetchData = async () => {
                    try {
                        setDeparturePrice(null);
                        setReturnPrice(null);
                        setDeparturePriceShared(null);
                        setReturnPriceShared(null);
                        setDepartureDiscount(null);
                        setReturnDiscount(null);
                        setDepartureDiscountShared(null);
                        setReturnDiscountShared(null);
                        setFlatDiscount(null);
                        calcPrice(
                            (sendDepartureData ? form.departureDate + ' ' + form.departureTime: null), 
                            (sendReturnData ? form.returnDate + ' ' + form.returnTime: null), 
                            (sendDepartureData ? form.departureAirport: null), 
                            (sendReturnData ? form.returnAirport: null), 
                            (sendDepartureData ? `${form.full_address}` :  `${form.dropoff_full_address}`),
                            ((sendDepartureData && sendReturnData) ?`${form.dropoff_full_address}`:null),
                            form.couponcode,
                            (sendDepartureData ? mapStopOversToAddresses(form.departure_stopovers) : []),
                            (sendReturnData ? mapStopOversToAddresses(form.return_stopovers) : []),
                            shareOptionAllowed('departure')? form.departureShared : 'private',
                            shareOptionAllowed('return')? form.returnShared : 'private',
                            departurePlaceId,
                            returnPlaceId,
                            form.Bac,
                        )
                        .then((response)=>{
                            if (response.ok) {
                                setValidAddress(true);
                                removeAddressErrors();

                            }

                            setLoadingPrice(false);
                            response.json().then(data => {
                                if (response.ok) {
                                    setDeparturePrice(data.price_departure);
                                    setDeparturePriceShared(data.price_departure_shared);
                                    if (form.wantsDepartureTransfer && form.wantsReturnTransfer && form.dropoff_full_address == '') {
                                        setReturnPrice(0);
                                        setReturnPriceShared(0);
                                    } else {
                                        setReturnPrice(data.price_return);
                                        setReturnPriceShared(data.price_return_shared);
                                    }
                                    setDepartureDiscount(data.discount_departure);
                                    setReturnDiscount(data.discount_return);
                                    setDepartureDiscountShared(data.discount_departure_shared);
                                    setReturnDiscountShared(data.discount_return_shared);
                                    setFlatDiscount(data.flat_discount);
                                    setValidCode(data.valid_code);
                                    setLastvalidatedCode(form.couponcode);
                                } else {
                                    let errors = Object.keys(data.error.missing_parameters);

                                    errors.forEach(parameter => {
                                        switch(parameter){
                                            case 'departure_date':

                                                addError(data.error.minDate.split("T")[0] != form.departureDate ? 'departureDate' : 'departureTime');
                                                break;
                                            case 'return_date':
                                                addError(data.error.minDate.split("T")[0] != form.returnDate ? 'returnDate' : 'returnTime');
                                                break;
                                        }
                                    });
                                    
                                    data.error.minDate != undefined && data.error.minDate > form.minDate && changeFormData('minDate', data.error.minDate, form);


                                    if (!(data.error.departure_failed_waypoint_index == undefined && data.error.return_failed_waypoint_index == undefined)){
                                        addAddressErrors();
                                    } else  {
                                        setStopoverErrors(data.error);
                                    } 
                                }
                            });
                        })
                        .catch((e)=>{
                            console.log(e);
                        });
                    } catch (e) {
                        console.log(e);
                    }
                };
                fetchData();
            } else {
                setLoadingPrice(false);
            }
        }, 1000);
    }

    const handleChangeReturnDate = (date) => {
        let temp ={...form};

        temp.returnDate = date;
        removeError('returnDate');
        setForm(temp);
    }

    const handleChangeDepartureTime = (time) => {
        let temp ={...form};
        
        temp.departureTime = time;
        removeError('departureDate');

        let departureTime = moment(form.departureDate + ' ' + time);
        let minDate = moment(form.minDate);

        if(departureTime.isAfter(minDate))
        {
            temp.departureTime = time

        } else {
            temp.departureTime = (minDate.hours() < 10 ? "0" + minDate.hours() : minDate.hours()) + ':' + (minDate.minutes() < 10 ? "0" + minDate.minutes() : minDate.minutes());                
        }

        setForm(temp);
    }

    const handleChangeReturnTime = (time) => {
        let temp ={...form};
        
        temp.returnTime = time;
        removeError('returnDate');

        let returnTime = moment(form.returnDate + ' ' + time);
        let minDate = moment(form.minDate);

        if(returnTime.isAfter(minDate))
        {
            temp.returnTime = time

        } else {
            temp.returnTime = (minDate.hours() < 10 ? "0" + minDate.hours() : minDate.hours()) + ':' + (minDate.hours() < 10 ? "0" + minDate.minutes() : minDate.minutes());                
        }

        setForm(temp);
    }
    
    const checkRequiredInputs = (pageToCheck) => {
        let wrongInputs = [];
        requiredInputsperPage[pageToCheck].forEach((s)=>{
            let state = form[s];
            if (state === null || state === '' || state === undefined || state === false || (s =='phone' && state.replace(/\D/g, '').length < 12)) {
                wrongInputs.push(s);
            }
        });
        if(departurePlaceIdError != null && departurePlaceIdError != ''){
            wrongInputs.push('departurePlaceIdError');
        }
        if(returnPlaceIdError != null && returnPlaceIdError != ''){
            wrongInputs.push('returnPlaceIdError');
        }
        setInputErrors(wrongInputs);

        return wrongInputs.length == 0;
    }

    const amountOfPeople = () => {        
        return parseInt(form.adults) + parseInt(form.children121) + parseInt(form.infants);
    }

    const amountOfCargo = () => {
        return parseInt(form.suitcases) + parseInt(form.bikecases) + parseInt(form.babycarriages) + parseInt(form.wheelchairs) + parseInt(form.skies) + parseInt(form.rollators) + parseInt(form.golfbags);
    }

    const amountOfExtraCargo = () => {
        return parseInt(form.bikecases) + parseInt(form.babycarriages) + parseInt(form.wheelchairs) + parseInt(form.skies) + parseInt(form.rollators) + parseInt(form.golfbags);
    }

    const checkAmountOfPeople = () => {        
        return amountOfPeople() <= 7;
    }

    const checkAmountOfCargo = (addedValue = 0) => {
        if (Number.isInteger(form.suitcases)) {
            return amountOfCargo() <= 7   
        } else {
            return true;
        }
    }

    const checkStopOvers = () => {
        return form?.departure_stopovers?.filter(s => (s.street == '' || s.zip == '' || s.city == '')).length == 0 && form?.return_stopovers?.filter(s => (s.street == '' || s.zip == '' || s.city == '')).length == 0;
    }

    const checkDropoffAddress = () => {
        if (form.dropoff_full_address == '') {
            let temp = [...inputErrors]; 
            dropoffAddressErrors.forEach((c)=> {temp.push(c)}); 
            setInputErrors(temp);
        }
        return form.dropoff_full_address != '';
    }

    const handlePageChange = async (pageNumber) => {
        let sendDepartureData = (!form.departureFlowFromAirport || (!form.returnFlowFromAirport && form.transferType == 'with_retour')) && form.full_address?.length > 0 && form.departureAirport?.length > 0 && form.departureDate?.length > 0 && form.departureTime?.length > 0;
        let sendReturnData = (form.departureFlowFromAirport || (form.returnFlowFromAirport && form.transferType == 'with_retour')) && form.dropoff_full_address?.length > 0 && form.returnDate?.length > 0 && form.returnTime?.length > 0 && form.returnAirport?.length > 0;

        if (pageNumber == 1) {
            let temp = [...inputErrors];
            
            if(form.departureFlight == 'TB0000' && sendDepartureData) {
                temp.push('departureFlight');
                setInputErrors(temp);
            } else if(form.returnFlight == 'TB0000' && sendReturnData) {
                temp.push('returnFlight');
                setInputErrors(temp);
            } else if (!checkAmountOfPeople()) {
                passengerErrors.forEach((c)=> {temp.push(c)});
                setInputErrors(temp);
            } else if(!checkAmountOfCargo()){
                cargoVariables.forEach((c)=> {temp.push(c)});
                setInputErrors(temp);
            } else if ((page < pageNumber && checkRequiredInputs(page) && validAddress && checkStopOvers() && checkDropoffAddress() && checkAmountOfPeople() && checkAmountOfCargo()) || page > pageNumber) {
                setPage(pageNumber);
            }
        } else if (pageNumber == 2){
            if (checkRequiredInputs(page)) {
                setCreatingOrder(true);
                let resp = await createOrder({...form, wantsDepartureTransfer: (!form.departureFlowFromAirport || (!form.returnFlowFromAirport && form.transferType == 'with_retour')), departurePlaceId: departurePlaceId, wantsReturnTransfer: (form.departureFlowFromAirport || (form.returnFlowFromAirport && form.transferType == 'with_retour')), returnPlaceId: returnPlaceId, departureShared: (shareOptionAllowed('departure')? form.departureShared : 'private'), returnShared: (shareOptionAllowed('return')? form.returnShared : 'private')});
                let json = await resp.json();
                if (resp.ok) {
                    window.location.href = json.redirectUrl;
                } else {
                    setSubmitErrors(json.errors);
                }
                setCreatingOrder(false);
            }
        } else if(pageNumber < page){
            setPage(pageNumber);
        }
    }

    const changeFormData = (field, value, updateObject=form) => {
        let temp={...updateObject}; 
        temp[field] = value; 
        setForm(temp);
        return temp;
    }

    const updateCargo = () => {
        setCargo(printFormData(cargoVariables));
        // setChildseats(printFormData(childseatVariables));
    }

    const printPaxData = () => {
        let data = [{name: 'adults', labels:[labels.adult, labels.adults] , amount: parseInt(form.adults)}, {name: 'children121', labels:[labels.child, labels.children] , amount: parseInt(form.children121)}, {name: 'infants', labels:[labels.infant, labels.infants] , amount: parseInt(form.infants)}].filter((e)=>{
            return e.amount > 0;
        });
        let dataString = '';

        
        data.forEach((c, i) => {
            dataString += (c.amount + ' ');
            dataString += (c.amount == 1 ? c.labels[0] : c.labels[1]);
            if (i < data.length-2) {
                dataString += (', ');
            } else if(i == data.length-2){
                dataString += (' ' + labels.and + ' ');
            }
        });

        return dataString;
    }
    const printFormData = (variables) => {
        let data = [];
        let dataString = '';
        variables.forEach((c) => {
            if (form[c] > 0) {
                let label =  labels[c];
                if (form[c] == 1) {
                    label =  labels[c+'_single'];
                }
                data.push({name: label, amount: form[c]});
            }
        });
        if (data.length > 0) {
            data.forEach((c, i) => {
                dataString += (c.amount + ' ');
                dataString += c.name;
                if (i < data.length-2) {
                    dataString += (', ');
                } else if(i == data.length-2){
                    dataString += (' ' + labels.and + ' ');
                }
            });
        }
        return dataString;
    }

    const addCargoErrors = ()=>{
        let temp = [...inputErrors]; 
        cargoVariables.forEach((c)=> {temp.push(c)}); 
        setInputErrors(temp)
    }

    const removeCargoErrors = ()=>{
        let temp = [...inputErrors];
        setInputErrors(temp.filter((err)=>{
            return !cargoVariables.includes(err);
        }));
    }

    const addAddressErrors = ()=>{
        let temp = [...addressErrors]; 
        addressErrors.forEach((c)=> {temp.push(c)}); 
        setInputErrors(temp)
    }

    const removeAddressErrors = ()=>{
        let temp = [...addressErrors];
        setInputErrors(temp.filter((err)=>{
            return !addressErrors.includes(err);
        }));
    }

    const addError = (error)=>{
        !inputErrors.includes(error) && setInputErrors([...inputErrors, error]);
    }

    const removeError = (error)=>{
        setInputErrors(inputErrors.filter((err)=>{
            return error != err;
        }));
    }

    useEffect(()=>{
        if (form.same_return_stopovers) {
            changeFormData('return_stopovers', [...form.departure_stopovers].reverse());
        }
    },[form.departure_stopovers]);

    useEffect(() => {
        if (Array.isArray(form.departure_stopovers) && Array.isArray(form.return_stopovers)) {
            const delay = setTimeout(() => {
                getPrices()
            }, 1000);
            return () => clearTimeout(delay);
        }
    }, [form.departure_stopovers, form.return_stopovers]);

    const totalDiscount = () => {
        return (((form.departureShared === "shared" && shareDiscountApplicable('departure')) ? (departurePrice - departurePriceShared + departureDiscountShared?.amount) : departureDiscount?.amount) + ((form.returnShared === "shared" && shareDiscountApplicable('return')) ? (returnPrice - returnPriceShared + returnDiscountShared?.amount) : returnDiscount?.amount) + flatDiscount?.amount) 
    }

    const totalShareDiscount = () => {
        return (((form.departureShared === "shared" && shareDiscountApplicable('departure')) ? (departurePrice - departurePriceShared) : 0) + ((form.returnShared === "shared" && shareDiscountApplicable('return')) ? (returnPrice - returnPriceShared) : 0)) 
    }

    const totalCouponDiscount = () => {
        return (((form.departureShared === "shared" && shareDiscountApplicable('departure')) ? (departureDiscountShared?.amount) : departureDiscount?.amount) + ((form.returnShared === "shared" && shareDiscountApplicable('return')) ? (returnDiscountShared?.amount) : returnDiscount?.amount) + flatDiscount?.amount) 
    }

    const handleTransferTypeChange = (type) => {
        let temp={...form}; 
        temp.transferType = type;
        if(type=='with_retour'){
            temp.wantsDepartureTransfer = true;
            temp.wantsReturnTransfer = true;
        } else {
            // @TODO: add only return transfer
            temp.wantsDepartureTransfer = true;
            temp.wantsReturnTransfer = false;
        }

        setForm(temp);
        return temp;
    }

    const swapTransferType = () => {
        let temp={...form};


        temp.returnFlowFromAirport = !temp.returnFlowFromAirport;
        temp.departureFlowFromAirport = !temp.departureFlowFromAirport;

        setForm(temp);
        return temp;
    }

    const shareDiscountApplicable = (type) => {
        let today = new Date();
        today.setHours(0,0,0,0);
        if(
            !(shareDiscountSetting?.airports??[]).includes((type == 'departure' ? form.departureAirport : form.returnAirport)) || 
            !(shareDiscountSetting?.flight_number_prefixes??[]).includes((type == 'departure' ? form.departureFlight.slice(0, 2).toUpperCase() : form.returnFlight.slice(0, 2).toUpperCase())) || 
            ((shareDiscountSetting?.max_suitcases??3) < form.suitcases) ||
            ((shareDiscountSetting?.max_other_cargo??0) < amountOfExtraCargo()) ||
            ((shareDiscountSetting?.max_pax??3) < amountOfPeople()) ||
            ((shareDiscountSetting?.days_before_transfer??28) >= diffInDays(today, (type == 'departure' ? new Date(Date.parse(form.departureDate+'T'+form.departureTime+':00')) : new Date(Date.parse(form.returnDate+'T'+form.returnTime+':00'))))) ||
            !shareOptionAllowed(type)
        ){
            return false;
        }
        return true;
    }

    // don't show options if before 01/04/2025
    const shareOptionAllowed = (type) => {
        let reference = new Date(Date.parse('2025-03-31T23:59:59'));
        let date = new Date(Date.parse(form[type + 'Date']+'T'+form[type + 'Time']+':00')) 

        return  date > reference;
    }

    const handleFlightChange = (type, value) => {
        clearTimeout(flightTimer);
        flightTimer = setTimeout(() => {
            let error = (type == 'departure' ? 'departureFlight' : 'returnFlight');
            if(value.replace(/[^a-zA-Z]/g,"").length != 2 || value.replace(/[^0-9]/g,"").length == 0 || value.replace(/[^0-9]/g,"").length > 4){
                let temp = [...inputErrors, error];
                setInputErrors(temp);
                return false;
            } else {
                let temp = [...inputErrors];
                setInputErrors(temp.filter((err) => {
                    return err != error;
                }));
                return true;
            }
        }, 1000);
    }

    const handleItemClick = (i) => {
        switch (i) {
            case 0:
            case 1:
                handlePageChange(i)
                break;
        
            default:
                break;
        }
    }

    return (
        <>
            {props && form && labels && 
                <>
                    <div className='reservation-page'>
                        <div className="row my-5">
                            <div className="col-lg-5 offset-lg-1 d-flex">
                                {tuiVip ? 
                                    <h2 className='my-auto'>{labels.booktransfer_tui_vip}</h2>
                                :
                                    <h2 className='my-auto'>{labels.booktransfer}</h2>
                                }
                            </div>
                            <div className="col-lg-5">
                                <ReservationProgressBar labels={[labels.transferinfo, (isTravelAgent? labels.clientinfo : labels.personalinfo), (shouldPayImmediately? labels.payment : null)]} page={page} handleItemClick={handleItemClick}/>
                            </div>
                        </div>
                        <div className="row flex-column-reverse flex-lg-row">
                            <div className="col-lg-7 offset-lg-1 pr-lg-5 reservation-page-form">
                                {page == 0 && 
                                    <>
                                        {isTravelAgent && 
                                            <>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <h4>
                                                            {labels.po}
                                                        </h4>
                                                        <input name='po' className={inputErrors.includes('po')?'is-invalid':''} value={form.po} onChange={(e)=>{changeFormData('po', e.currentTarget.value)}} type="text"  placeholder={capitalizeFirstLetter(labels.po)} />
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <h4>
                                                            {labels.responsibletravelagent}
                                                        </h4>
                                                        <input name='travelagent_name' className={inputErrors.includes('travelagent_name')?'is-invalid':''} value={form.travelagent_name} onChange={(e)=>{changeFormData('travelagent_name', e.currentTarget.value)}} type="text"  placeholder={capitalizeFirstLetter(labels.responsibletravelagent)} />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {
                                            !form.transferTypeFromQuery &&
                                        <div className="row">
                                            <div className="col-12">
                                                <hr className='dashed'/>
                                            </div>
                                            <div className="col-12 pl-4 my-1">
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <label htmlFor="single_transfer" className="mb-0">
                                                                <input type="radio" value="single" name="transfer_type" id="single_transfer" checked={form.transferType === "single" || "inbound" || "outbound"} className="mb-0 mr-1" onChange={(e)=>{handleTransferTypeChange(e.currentTarget.value)}}/>
                                                            {labels.transfer_single}
                                                        </label>
                                                    </div>
                                                    <div className="col-auto">
                                                        <label htmlFor="retourt_transfer" className="mb-0">
                                                            <input type="radio" value="with_retour" name="transfer_type" id="retourt_transfer" checked={form.transferType === "with_retour"} className="mb-0 mr-1" onChange={(e)=>{handleTransferTypeChange(e.currentTarget.value)}}/>
                                                            {labels.transfer_with_retour} 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }

                                        {form.wantsDepartureTransfer && 

                                            (form.departureFlowFromAirport 
                                            ? <div className="row">
                                                <div className="col-12">
                                                    <hr className='dashed'/>
                                                </div>
                                                <div className="col-12">
                                                    <h4>
                                                        {labels.departuretransport}
                                                    </h4>
                                                </div>
                                                <div className={"col-lg-4 mb-2"+ (form.transferType == "single" ? ' pr-0' : '')}>
                                                    <div className="d-flex flex-row">
                                                        <label className='highlighted'>
                                                            {labels.from}
                                                        </label>
                                                    </div>
                                                    <div className="d-flex flex-row flex-nowrap align-items-center">
                                                        <select name="return_airport" className={inputErrors.includes('returnAirport')?'is-invalid':''} value={form.returnAirport} onChange={(e)=>{changeFormData('returnAirport', e.currentTarget.value)}}>
                                                            <option value="" disabled>{labels.selectairport}</option>
                                                            {destinations && destinations.length >0 && destinations.map((d, i)=>{
                                                                return <option value={d.destination_code} key={'ret_destination' + i}>{d.destination_name}</option>
                                                            })}
                                                        </select>
                                                        <div className="swap-arrows ml-2" onClick={()=>{swapTransferType()}}>
                                                            <span className="arrow arrow-left"></span>
                                                            <span className="arrow arrow-right"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-2">
                                                    <label htmlFor="" className='highlighted'>
                                                        {labels.to}
                                                    </label>
                                                    <div>
                                                        <AutoCompleteInput 
                                                            extraClasses={(returnPlaceIdError != null && returnPlaceIdError != '')?'is-invalid':''}
                                                            value={form.dropoff_full_address} 
                                                            setValue={(value)=>{
                                                                let temp = changeFormData('dropoff_full_address', value);
                                                                temp = changeFormData('returnRowClicked', false, temp);
                                                                return temp;
                                                            }} 
                                                            onRowClick={(place_id, formData)=>{
                                                                changeFormData('returnRowClicked', true, formData);
                                                                setReturnPlaceId(place_id); 
                                                                setRowClicked(!rowClicked);
                                                            }} 
                                                            labels={labels}
                                                            errors={inputErrors}
                                                            errorIds={['dropoff_full_address', 'returnRowClicked']}
                                                        />
                                                        {returnPlaceIdError && 
                                                            <p className='text-danger pt-2 mb-0'>
                                                                {returnPlaceIdError}
                                                            </p>
                                                        }
                                                    </div>
                                                </div>
                                                {shareOptionAllowed('return') && 
                                                    <div className="col-lg-4 mb-2 d-flex flex-column justify-content-end gap-2">
                                                        <label htmlFor="return_private" className="mb-0">
                                                            <input type="radio" value="private" name="return_shared" id="return_private" checked={form.returnShared === "private"} className="mb-0 mr-1" onChange={(e)=>{changeFormData('returnShared', e.currentTarget.value)}}/>
                                                            {labels.transfer_individual}
                                                        </label>
                                                        <label htmlFor="return_shared" className="mb-0">
                                                            <input type="radio" value="shared" name="return_shared" id="return_shared" checked={form.returnShared === "shared"} className="mb-0 mr-1" onChange={(e)=>{changeFormData('returnShared', e.currentTarget.value)}}/>
                                                            {labels.transfer_shared}
                                                            <button className='btn btn-info-modal' type='button' data-toggle="modal" data-target="#share_info_modal">&#8505;</button>
                                                            <span className="shared_discount_label" style={{opacity: (returnPrice && returnPriceShared && shareDiscountApplicable('return')? 1 : 0)}}>
                                                                {'-€'+(Math.round((returnPrice/*-returnDiscount?.amount*/ - returnPriceShared/*-returnDiscountShared?.amount*/) * 100) / 100).toFixed(2)}
                                                            </span>
                                                        </label>
                                                    </div>
                                                }
                                                <div className="col-12">
                                                    <label className='highlighted'>
                                                        {labels.flightinfo}
                                                        <img src="/images/website/icons/Vliegtuig.svg" alt="airplane" className='flighticon'/>
                                                    </label>
                                                </div>
                                                <div className="col-sm-6 col-lg-auto mb-2">
                                                    <label>{labels.date} <br />
                                                        <input type="date" name='return_date' style={{width: 'auto'}} className={inputErrors.includes('returnDate')?'is-invalid':''} value={form.returnDate} onChange={(e)=>{handleChangeReturnDate(e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.returndate)} min={minReturnDate.split("T")[0]}  max={moment(minReturnDate).add(2, 'y').format('Y-MM-D')}/>
                                                    </label>
                                                </div>
                                                <div className="col-sm-6 col-lg-auto mb-2">
                                                    <label>{labels.flight_return_time} <br />
                                                        <input type="time" name='return_time' style={{width: 'auto'}} className={inputErrors.includes('returnTime')?'is-invalid':''} value={form.returnTime} onChange={(e)=>{handleChangeReturnTime(e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.returntime)}/>
                                                    </label>
                                                </div>
                                                <div className="col-lg-auto mb-2">
                                                    <label>{labels.flight} <br />
                                                        <input type="text" name='return_flight' style={{width: '120px', color: form.returnFlight == 'TB0000'? 'grey' : 'black'}} className={inputErrors.includes('returnFlight')?'is-invalid':''} value={form.returnFlight} onChange={(e)=>{changeFormData('returnFlight', e.currentTarget.value); handleFlightChange('return', e.currentTarget.value)}} onClick={(e) => {if(e.currentTarget.value == "TB0000"){changeFormData('returnFlight', '', form)}}} placeholder={capitalizeFirstLetter(labels.flightcode_example)}/>
                                                    </label>
                                                    {inputErrors.includes('returnFlight') && form.returnFlight.length > 0 && 
                                                        <>
                                                            {
                                                                !form.returnFlight == "TB0000" ?
                                                                    <p className='text-danger'>{labels.flight_not_changed}</p> 
                                                                :
                                                                    form.returnFlight.replace(/[^a-zA-Z]/g,"").length != 2 ?
                                                                        <p className='text-danger'>{labels.invalid_flight_number_error_characters}</p> 
                                                                    :
                                                                        (
                                                                            form.returnFlight.replace(/[^0-9]/g,"").length == 0 ?
                                                                                <p className='text-danger'>{labels.invalid_flight_number_error_no_numbers}</p>
                                                                            :
                                                                                (form.returnFlight.replace(/[^0-9]/g,"").length > 4 ?
                                                                                    <p className='text-danger'>{labels.invalid_flight_number_error_too_many_numbers}</p>
                                                                                :
                                                                                    ''
                                                                            )
                                                                        )
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                {isHotel && 
                                                    <>
                                                        <div className="col-12">
                                                            <h5>
                                                                {labels.checkindescription}
                                                            </h5>
                                                        </div>
                                                        <div className="col-lg-8 col-xl-6 d-flex flex-direction-row align-items-center">
                                                            <input type="number" name='checkin' className={inputErrors.includes('checkin')?'is-invalid':''} value={form.checkin} onChange={(e)=>{changeFormData('checkin', e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.checkin)} min={2}/>
                                                            <p className='d-inline-block ms-2'>
                                                                {labels.twohourinfo}
                                                            </p>
                                                        </div>
                                                    </>
                                                }
                                                <div className='col-12'>
                                                    <div className="col-7 pl-0 mb-2">
                                                        <label htmlFor="destination">
                                                            {labels.destinationquestion}
                                                            <input type="text" name='destination' id='destination' className={inputErrors.includes('destination')?'is-invalid':''} value={form.destination} onChange={(e)=>{changeFormData('destination', e.currentTarget.value)}} placeholder={'ABC'}/>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            : <div className="row">
                                                <div className="col-12">
                                                    <hr className='dashed'/>
                                                </div>
                                                <div className="col-12">
                                                    <h4>
                                                        {labels.departuretransport}
                                                    </h4>
                                                </div>
                                                <div className="col-12">
                                                    {stopoverErrors && stopoverErrors.departure_failed_waypoint_index != undefined && stopoverErrors.departure_failed_waypoint_index == 0 && 
                                                        <p className='text-danger'>{labels.invalidaddresserror}</p>
                                                    }
                                                </div>
                                                <div className={"col-lg-4 mb-2"+ (form.transferType == "single" ? ' pr-0' : '')}>
                                                    <div className="d-flex flex-row">
                                                        <label className='highlighted'>
                                                            {labels.from}
                                                        </label>
                                                    </div>
                                                    <div className="d-flex flex-row flex-nowrap align-items-center">
                                                        <div className='w-100'>
                                                            <AutoCompleteInput 
                                                                extraClasses={(departurePlaceIdError != null && departurePlaceIdError != '')?'is-invalid':''}
                                                                value={form.full_address} 
                                                                setValue={(value) => {
                                                                    let temp = form; 
                                                                    temp = changeFormData('full_address', value, temp);
                                                                    changeFormData('departureRowClicked', false, temp);
                                                                    if(form.full_address == form.dropoff_full_address){
                                                                        temp = changeFormData('dropoff_full_address', value, temp);
                                                                        temp = changeFormData('returnRowClicked', false, temp);
                                                                    }
                                                                    return temp;
                                                                }} 
                                                                onRowClick={(place_id, formData)=>{
                                                                    let temp = changeFormData('departureRowClicked', true, formData);
                                                                    if(form.full_address == form.dropoff_full_address){ 
                                                                        setReturnPlaceId(place_id);
                                                                        changeFormData('returnRowClicked', true, temp);
                                                                    } 
                                                                    setDeparturePlaceId(place_id); 
                                                                    setRowClicked(!rowClicked);
                                                                }} 
                                                                labels={labels}
                                                                errors={inputErrors}
                                                                errorIds={['full_address', 'departureRowClicked']}
                                                            />
                                                            {departurePlaceIdError && 
                                                                <p className='text-danger pt-2 mb-0'>
                                                                    {departurePlaceIdError}
                                                                </p>
                                                            }
                                                        </div>
                                                        <div className="swap-arrows ml-2" onClick={()=>{swapTransferType()}}>
                                                            <span className="arrow arrow-left"></span>
                                                            <span className="arrow arrow-right"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-2">
                                                    <label htmlFor="" className='highlighted'>
                                                        {labels.to}
                                                    </label>
                                                    <select name="departure_airport" className={inputErrors.includes('departureAirport')?'is-invalid':''} value={form.departureAirport} onChange={(e)=>{let temp = form; if(form.departureAirport == form.returnAirport){temp = changeFormData('returnAirport', e.currentTarget.value)} changeFormData('departureAirport', e.currentTarget.value, temp)}}>
                                                        <option value="" disabled>{labels.selectairport}</option>
                                                        {destinations && destinations.length >0 && destinations.map((d, i)=>{
                                                            return <option value={d.destination_code} key={'dep_destination' + i}>{d.destination_name}</option>
                                                        })}
                                                    </select>
                                                </div>
                                                {shareOptionAllowed('departure') && 
                                                    <div className="col-lg-4 mb-2 d-flex flex-column justify-content-end gap-2">
                                                        <label htmlFor="departure_private" className="mb-0">
                                                            <input type="radio" value="private" name="departure_shared" id="departure_private" checked={form.departureShared === "private"} className="mb-0 mr-1" onChange={(e)=>{let temp = form; if(form.departureShared == form.returnShared){temp = changeFormData('returnShared', e.currentTarget.value)} changeFormData('departureShared', e.currentTarget.value, temp)}}/>
                                                            {labels.transfer_individual}
                                                        </label>
                                                        <label htmlFor="departure_shared" className="mb-0">
                                                            <input type="radio" value="shared" name="departure_shared" id="departure_shared" checked={form.departureShared === "shared"} className="mb-0 mr-1" onChange={(e)=>{let temp = form; if(form.departureShared == form.returnShared){temp = changeFormData('returnShared', e.currentTarget.value)} changeFormData('departureShared', e.currentTarget.value, temp)}}/>
                                                            {labels.transfer_shared}
                                                            <button className='btn btn-info-modal' type='button' data-toggle="modal" data-target="#share_info_modal">&#8505;</button>
                                                            <span className="shared_discount_label" style={{opacity: (departurePrice && departurePriceShared && shareDiscountApplicable('departure') ? 1 : 0)}}>
                                                                {'-€'+(Math.round((departurePrice/*-departureDiscount?.amount*/ - departurePriceShared/*-departureDiscountShared?.amount*/) * 100) / 100).toFixed(2)}
                                                            </span>
                                                        </label>
                                                    </div>
                                                }
                                                <div className="col-12">
                                                    <label className='highlighted'>
                                                        {labels.flightinfo}
                                                        <img src="/images/website/icons/Vliegtuig.svg" alt="airplane" className='flighticon'/>
                                                    </label>
                                                </div>
                                                <div className="col-sm-6 col-lg-auto mb-2">
                                                    <label>{labels.date} <br />
                                                        <input type="date" name='departure_date' style={{width: 'auto'}}  className={inputErrors.includes('departureDate')?'is-invalid':''} value={form.departureDate} onChange={(e)=>{removeError('departureDate');changeFormData('departureDate', e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.departuredate)} min={minDate.split("T")[0]} max={moment(minDate).add(2, 'y').format('Y-MM-D')}/>
                                                    </label>
                                                </div>
                                                <div className="col-sm-6 col-lg-auto mb-2">
                                                    <label>{labels.flight_departure_time} <br />
                                                        <input type="time" name='departure_time' style={{width: 'auto'}} className={inputErrors.includes('departureTime')?'is-invalid':''} value={form.departureTime} onChange={(e)=>{handleChangeDepartureTime(e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.departuretime)}/>
                                                    </label>
                                                </div>
                                                <div className="col-lg-auto mb-2">
                                                    <label>{labels.flight} <br />
                                                        <input type="text" name='departure_flight' style={{width: '120px', color: form.departureFlight == 'TB0000'? 'grey' : 'black'}} className={inputErrors.includes('departureFlight')?'is-invalid':''} value={form.departureFlight} onChange={(e)=>{changeFormData('departureFlight', e.currentTarget.value); handleFlightChange('departure', e.currentTarget.value)}} onClick={(e) => {if(e.currentTarget.value == "TB0000"){changeFormData('departureFlight', '', form)}}} placeholder={capitalizeFirstLetter(labels.flightcode_example)}/>
                                                    </label>
                                                    {inputErrors.includes('departureFlight') && form.departureFlight.length > 0 && 
                                                        <>
                                                            {
                                                            form.departureFlight == "TB0000" ?
                                                                <p className='text-danger'>{labels.flight_not_changed}</p> 
                                                            :
                                                                form.departureFlight.replace(/[^a-zA-Z]/g,"").length != 2 ?
                                                                    <p className='text-danger'>{labels.invalid_flight_number_error_characters}</p> 
                                                                :
                                                                    (
                                                                        form.departureFlight.replace(/[^0-9]/g,"").length == 0 ?
                                                                            <p className='text-danger'>{labels.invalid_flight_number_error_no_numbers}</p>
                                                                        :
                                                                            (form.departureFlight.replace(/[^0-9]/g,"").length > 4 ?
                                                                                <p className='text-danger'>{labels.invalid_flight_number_error_too_many_numbers}</p>
                                                                            :
                                                                                ''
                                                                        )
                                                                    )
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                {!isHotel && 
                                                    <>
                                                        <div className="col-12">
                                                            <label htmlFor='checkin'>
                                                                {labels.checkindescription}
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-6 d-flex flex-row align-items-center  mb-2">
                                                            <input type="number" name='checkin' id='checkin' className={inputErrors.includes('checkin')?'is-invalid':''} value={form.checkin} onChange={(e)=>{changeFormData('checkin', e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.checkin)} min={2} step={.5}/>
                                                            <p className='d-inline-block ms-2 mb-0'>
                                                                {labels.twohourinfo}
                                                            </p>
                                                        </div>
                                                    </>
                                                }
                                                <div className='col-12'>
                                                    <div className="col-7 pl-0 mb-2">
                                                        <label htmlFor="destination">
                                                            {labels.destinationquestion_departure}
                                                            <input type="text" name='destination' id='destination' className={inputErrors.includes('destination')?'is-invalid':''} value={form.destination} onChange={(e)=>{changeFormData('destination', e.currentTarget.value)}} placeholder={'ABC'}/>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>)
                                        }   
                                        {form.wantsReturnTransfer &&

                                            (form.returnFlowFromAirport
                                            ? <div className="row">
                                                <div className="col-12">
                                                    <hr className='dashed'/>
                                                </div>
                                                <div className="col-12">
                                                    <h4>
                                                        {labels.returntransport}
                                                    </h4>
                                                </div>
                                                <div className={"col-lg-4 mb-2"+ (form.transferType == "single" ? ' pr-0' : '')}>
                                                    <div className="d-flex flex-row">
                                                        <label className='highlighted'>
                                                            {labels.from}
                                                        </label>
                                                    </div>
                                                    <div className="d-flex flex-row flex-nowrap align-items-center">
                                                        <select name="return_airport" className={inputErrors.includes('returnAirport')?'is-invalid':''} value={form.returnAirport} onChange={(e)=>{changeFormData('returnAirport', e.currentTarget.value)}}>
                                                            <option value="" disabled>{labels.selectairport}</option>
                                                            {destinations && destinations.length >0 && destinations.map((d, i)=>{
                                                                return <option value={d.destination_code} key={'ret_destination' + i}>{d.destination_name}</option>
                                                            })}
                                                        </select>
                                                        <div className="swap-arrows ml-2" onClick={()=>{swapTransferType()}}>
                                                            <span className="arrow arrow-left"></span>
                                                            <span className="arrow arrow-right"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-2">
                                                    <label htmlFor="" className='highlighted'>
                                                        {labels.to}
                                                    </label>
                                                    <div>
                                                        <AutoCompleteInput 
                                                            extraClasses={(returnPlaceIdError != null && returnPlaceIdError != '')?'is-invalid':''}
                                                            value={form.dropoff_full_address} 
                                                            setValue={(value)=>{
                                                                let temp = changeFormData('dropoff_full_address', value);
                                                                temp = changeFormData('returnRowClicked', false, temp);
                                                                return temp;
                                                            }} 
                                                            onRowClick={(place_id, formData)=>{
                                                                changeFormData('returnRowClicked', true, formData);
                                                                setReturnPlaceId(place_id); 
                                                                setRowClicked(!rowClicked);
                                                            }} 
                                                            labels={labels}
                                                            errors={inputErrors}
                                                            errorIds={['dropoff_full_address', 'returnRowClicked']}
                                                        />
                                                        {returnPlaceIdError && 
                                                            <p className='text-danger pt-2 mb-0'>
                                                                {returnPlaceIdError}
                                                            </p>
                                                        }
                                                    </div>
                                                </div>
                                                {shareOptionAllowed('return') && 
                                                    <div className="col-lg-4 mb-2 d-flex flex-column justify-content-end gap-2">
                                                        <label htmlFor="return_private" className="mb-0">
                                                            <input type="radio" value="private" name="return_shared" id="return_private" checked={form.returnShared === "private"} className="mb-0 mr-1" onChange={(e)=>{changeFormData('returnShared', e.currentTarget.value)}}/>
                                                            {labels.transfer_individual}
                                                        </label>
                                                        <label htmlFor="return_shared" className="mb-0">
                                                            <input type="radio" value="shared" name="return_shared" id="return_shared" checked={form.returnShared === "shared"} className="mb-0 mr-1" onChange={(e)=>{changeFormData('returnShared', e.currentTarget.value)}}/>
                                                            {labels.transfer_shared}
                                                            <button className='btn btn-info-modal' type='button' data-toggle="modal" data-target="#share_info_modal">&#8505;</button>
                                                            <span className="shared_discount_label" style={{opacity: (returnPrice && returnPriceShared && shareDiscountApplicable('return')? 1 : 0)}}>
                                                                {'-€'+(Math.round((returnPrice/*-returnDiscount?.amount*/ - returnPriceShared/*-returnDiscountShared?.amount*/) * 100) / 100).toFixed(2)}
                                                            </span>
                                                        </label>
                                                    </div>
                                                }
                                                <div className="col-12">
                                                    <label className='highlighted'>
                                                        {labels.flightinfo}
                                                        <img src="/images/website/icons/Vliegtuig.svg" alt="airplane" className='flighticon'/>
                                                    </label>
                                                </div>
                                                <div className="col-sm-6 col-lg-auto mb-2">
                                                    <label>{labels.date} <br />
                                                        <input type="date" name='return_date' style={{width: 'auto'}} className={inputErrors.includes('returnDate')?'is-invalid':''} value={form.returnDate} onChange={(e)=>{handleChangeReturnDate(e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.returndate)} min={minReturnDate.split("T")[0]}  max={moment(minReturnDate).add(2, 'y').format('Y-MM-D')}/>
                                                    </label>
                                                </div>
                                                <div className="col-sm-6 col-lg-auto mb-2">
                                                    <label>{labels.flight_return_time} <br />
                                                        <input type="time" name='return_time' style={{width: 'auto'}} className={inputErrors.includes('returnTime')?'is-invalid':''} value={form.returnTime} onChange={(e)=>{handleChangeReturnTime(e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.returntime)}/>
                                                    </label>
                                                </div>
                                                <div className="col-lg-auto mb-2">
                                                    <label>{labels.flight} <br />
                                                        <input type="text" name='return_flight' style={{width: '120px', color: form.returnFlight == 'TB0000'? 'grey' : 'black'}} className={inputErrors.includes('returnFlight')?'is-invalid':''} value={form.returnFlight} onChange={(e)=>{changeFormData('returnFlight', e.currentTarget.value); handleFlightChange('return', e.currentTarget.value)}} onClick={(e) => {if(e.currentTarget.value == "TB0000"){changeFormData('returnFlight', '', form)}}} placeholder={capitalizeFirstLetter(labels.flightcode_example)}/>
                                                    </label>
                                                    {inputErrors.includes('returnFlight') && form.returnFlight.length > 0 && 
                                                        <>
                                                            {
                                                                !form.returnFlight == "TB0000" ?
                                                                    <p className='text-danger'>{labels.flight_not_changed}</p> 
                                                                :
                                                                    form.returnFlight.replace(/[^a-zA-Z]/g,"").length != 2 ?
                                                                        <p className='text-danger'>{labels.invalid_flight_number_error_characters}</p> 
                                                                    :
                                                                        (
                                                                            form.returnFlight.replace(/[^0-9]/g,"").length == 0 ?
                                                                                <p className='text-danger'>{labels.invalid_flight_number_error_no_numbers}</p>
                                                                            :
                                                                                (form.returnFlight.replace(/[^0-9]/g,"").length > 4 ?
                                                                                    <p className='text-danger'>{labels.invalid_flight_number_error_too_many_numbers}</p>
                                                                                :
                                                                                    ''
                                                                            )
                                                                        )
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                {isHotel && 
                                                    <>
                                                        <div className="col-12">
                                                            <h5>
                                                                {labels.checkindescription}
                                                            </h5>
                                                        </div>
                                                        <div className="col-lg-8 col-xl-6 d-flex flex-direction-row align-items-center">
                                                            <input type="number" name='checkin' className={inputErrors.includes('checkin')?'is-invalid':''} value={form.checkin} onChange={(e)=>{changeFormData('checkin', e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.checkin)} min={2}/>
                                                            <p className='d-inline-block ms-2'>
                                                                {labels.twohourinfo}
                                                            </p>
                                                        </div>
                                                    </>
                                                }
                                                <div className='col-12'>
                                                    <div className="col-7 pl-0 mb-2">
                                                        <label htmlFor="destination">
                                                            {labels.destinationquestion}
                                                            <input type="text" name='destination' id='destination' className={inputErrors.includes('destination')?'is-invalid':''} value={form.destination} onChange={(e)=>{changeFormData('destination', e.currentTarget.value)}} placeholder={'ABC'}/>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            : <div className="row">
                                                <div className="col-12">
                                                    <hr className='dashed'/>
                                                </div>
                                                <div className="col-12">
                                                    <h4>
                                                        {labels.returntransport}
                                                    </h4>
                                                </div>
                                                <div className="col-12">
                                                    {stopoverErrors && stopoverErrors.departure_failed_waypoint_index != undefined && stopoverErrors.departure_failed_waypoint_index == 0 && 
                                                        <p className='text-danger'>{labels.invalidaddresserror}</p>
                                                    }
                                                </div>
                                                <div className={"col-lg-4 mb-2"+ (form.transferType == "single" ? ' pr-0' : '')}>
                                                    <div className="d-flex flex-row">
                                                        <label className='highlighted'>
                                                            {labels.from}
                                                        </label>
                                                    </div>
                                                    <div className="d-flex flex-row flex-nowrap align-items-center">
                                                        <div className='w-100'>
                                                            <AutoCompleteInput 
                                                                extraClasses={(departurePlaceIdError != null && departurePlaceIdError != '')?'is-invalid':''}
                                                                value={form.full_address} 
                                                                setValue={(value) => {
                                                                    let temp = form; 
                                                                    temp = changeFormData('full_address', value, temp);
                                                                    changeFormData('departureRowClicked', false, temp);
                                                                    if(form.full_address == form.dropoff_full_address){
                                                                        temp = changeFormData('dropoff_full_address', value, temp);
                                                                        temp = changeFormData('returnRowClicked', false, temp);
                                                                    }
                                                                    return temp;
                                                                }} 
                                                                onRowClick={(place_id, formData)=>{
                                                                    let temp = changeFormData('departureRowClicked', true, formData);
                                                                    if(form.full_address == form.dropoff_full_address){ 
                                                                        setReturnPlaceId(place_id);
                                                                        changeFormData('returnRowClicked', true, temp);
                                                                    } 
                                                                    setDeparturePlaceId(place_id); 
                                                                    setRowClicked(!rowClicked);
                                                                }} 
                                                                labels={labels}
                                                                errors={inputErrors}
                                                                errorIds={['full_address', 'departureRowClicked']}
                                                            />
                                                            {departurePlaceIdError && 
                                                                <p className='text-danger pt-2 mb-0'>
                                                                    {departurePlaceIdError}
                                                                </p>
                                                            }
                                                        </div>
                                                        <div className="swap-arrows ml-2" onClick={()=>{swapTransferType()}}>
                                                            <span className="arrow arrow-left"></span>
                                                            <span className="arrow arrow-right"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-2">
                                                    <label htmlFor="" className='highlighted'>
                                                        {labels.to}
                                                    </label>
                                                    <select name="departure_airport" className={inputErrors.includes('departureAirport')?'is-invalid':''} value={form.departureAirport} onChange={(e)=>{let temp = form; if(form.departureAirport == form.returnAirport){temp = changeFormData('returnAirport', e.currentTarget.value)} changeFormData('departureAirport', e.currentTarget.value, temp)}}>
                                                        <option value="" disabled>{labels.selectairport}</option>
                                                        {destinations && destinations.length >0 && destinations.map((d, i)=>{
                                                            return <option value={d.destination_code} key={'dep_destination' + i}>{d.destination_name}</option>
                                                        })}
                                                    </select>
                                                </div>
                                                {shareOptionAllowed('departure') && 
                                                    <div className="col-lg-4 mb-2 d-flex flex-column justify-content-end gap-2">
                                                        <label htmlFor="departure_private" className="mb-0">
                                                            <input type="radio" value="private" name="departure_shared" id="departure_private" checked={form.departureShared === "private"} className="mb-0 mr-1" onChange={(e)=>{let temp = form; if(form.departureShared == form.returnShared){temp = changeFormData('returnShared', e.currentTarget.value)} changeFormData('departureShared', e.currentTarget.value, temp)}}/>
                                                            {labels.transfer_individual}
                                                        </label>
                                                        <label htmlFor="departure_shared" className="mb-0">
                                                            <input type="radio" value="shared" name="departure_shared" id="departure_shared" checked={form.departureShared === "shared"} className="mb-0 mr-1" onChange={(e)=>{let temp = form; if(form.departureShared == form.returnShared){temp = changeFormData('returnShared', e.currentTarget.value)} changeFormData('departureShared', e.currentTarget.value, temp)}}/>
                                                            {labels.transfer_shared}
                                                            <button className='btn btn-info-modal' type='button' data-toggle="modal" data-target="#share_info_modal">&#8505;</button>
                                                            <span className="shared_discount_label" style={{opacity: (departurePrice && departurePriceShared && shareDiscountApplicable('departure') ? 1 : 0)}}>
                                                                {'-€'+(Math.round((departurePrice/*-departureDiscount?.amount*/ - departurePriceShared/*-departureDiscountShared?.amount*/) * 100) / 100).toFixed(2)}
                                                            </span>
                                                        </label>
                                                    </div>
                                                }
                                                <div className="col-12">
                                                    <label className='highlighted'>
                                                        {labels.flightinfo}
                                                        <img src="/images/website/icons/Vliegtuig.svg" alt="airplane" className='flighticon'/>
                                                    </label>
                                                </div>
                                                <div className="col-sm-6 col-lg-auto mb-2">
                                                    <label>{labels.date} <br />
                                                        <input type="date" name='departure_date' style={{width: 'auto'}}  className={inputErrors.includes('departureDate')?'is-invalid':''} value={form.departureDate} onChange={(e)=>{removeError('departureDate');changeFormData('departureDate', e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.departuredate)} min={minDate.split("T")[0]} max={moment(minDate).add(2, 'y').format('Y-MM-D')}/>
                                                    </label>
                                                </div>
                                                <div className="col-sm-6 col-lg-auto mb-2">
                                                    <label>{labels.flight_departure_time} <br />
                                                        <input type="time" name='departure_time' style={{width: 'auto'}} className={inputErrors.includes('departureTime')?'is-invalid':''} value={form.departureTime} onChange={(e)=>{handleChangeDepartureTime(e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.departuretime)}/>
                                                    </label>
                                                </div>
                                                <div className="col-lg-auto mb-2">
                                                    <label>{labels.flight} <br />
                                                        <input type="text" name='departure_flight' style={{width: '120px', color: form.departureFlight == 'TB0000'? 'grey' : 'black'}} className={inputErrors.includes('departureFlight')?'is-invalid':''} value={form.departureFlight} onChange={(e)=>{changeFormData('departureFlight', e.currentTarget.value); handleFlightChange('departure', e.currentTarget.value)}} onClick={(e) => {if(e.currentTarget.value == "TB0000"){changeFormData('departureFlight', '', form)}}} placeholder={capitalizeFirstLetter(labels.flightcode_example)}/>
                                                    </label>
                                                    {inputErrors.includes('departureFlight') && form.departureFlight.length > 0 && 
                                                        <>
                                                            {
                                                            form.departureFlight == "TB0000" ?
                                                                <p className='text-danger'>{labels.flight_not_changed}</p> 
                                                            :
                                                                form.departureFlight.replace(/[^a-zA-Z]/g,"").length != 2 ?
                                                                    <p className='text-danger'>{labels.invalid_flight_number_error_characters}</p> 
                                                                :
                                                                    (
                                                                        form.departureFlight.replace(/[^0-9]/g,"").length == 0 ?
                                                                            <p className='text-danger'>{labels.invalid_flight_number_error_no_numbers}</p>
                                                                        :
                                                                            (form.departureFlight.replace(/[^0-9]/g,"").length > 4 ?
                                                                                <p className='text-danger'>{labels.invalid_flight_number_error_too_many_numbers}</p>
                                                                            :
                                                                                ''
                                                                        )
                                                                    )
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                {!isHotel && 
                                                    <>
                                                        <div className="col-12">
                                                            <label htmlFor='checkin'>
                                                                {labels.checkindescription}
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-6 d-flex flex-row align-items-center  mb-2">
                                                            <input type="number" name='checkin' id='checkin' className={inputErrors.includes('checkin')?'is-invalid':''} value={form.checkin} onChange={(e)=>{changeFormData('checkin', e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.checkin)} min={2} step={.5}/>
                                                            <p className='d-inline-block ms-2 mb-0'>
                                                                {labels.twohourinfo}
                                                            </p>
                                                        </div>
                                                    </>
                                                }
                                                <div className='col-12'>
                                                    <div className="col-7 pl-0 mb-2">
                                                        <label htmlFor="destination">
                                                            {labels.destinationquestion_departure}
                                                            <input type="text" name='destination' id='destination' className={inputErrors.includes('destination')?'is-invalid':''} value={form.destination} onChange={(e)=>{changeFormData('destination', e.currentTarget.value)}} placeholder={'ABC'}/>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>)
                                        }
                                        <hr className='dashed'/>
                                        <h4>
                                            {labels.passengers_and_lugage}
                                        </h4>
                                        <label className='highlighted'>
                                            {labels.passengers}
                                        </label>
                                        {inputErrors.some(err=> passengerErrors.includes(err))?
                                            <p className='text-danger'>{labels.peopleerror}</p>
                                        :
                                            ''
                                        }
                                        <div className="row">
                                            <div className="col-6 col-md-auto mb-2">
                                                <label style={{maxWidth: '130px'}}>
                                                    {labels.numberofadults} <br />
                                                    <input name='adults' className={inputErrors.includes('adults')?'is-invalid':''} value={form.adults} onChange={(e)=>{let temp = {...form}; if(e.currentTarget.value>0 || e.currentTarget.value == ''){ temp.adults = e.currentTarget.value; } else {temp.adults = 1} setForm(temp)}} type="number" min={1} onBlur={(e)=>{if(e.currentTarget.value < 0 || e.currentTarget.value == ''){changeFormData('adults', 0)}}}/>
                                                </label>
                                            </div>
                                            <div className="col-6 col-md-auto mb-2">
                                                <label style={{maxWidth: '130px'}}>
                                                    {labels.numberofchildren121} <br />
                                                    <input type="number" className={inputErrors.includes('children121')?'is-invalid':''} min={0} value={form.children121} onChange={(e)=>{let temp = {...form}; if(e.currentTarget.value>=0 || e.currentTarget.value == ''){ temp.children121 = e.currentTarget.value; } else {temp.children121 = 0} setForm(temp)}} onBlur={(e)=>{if(e.currentTarget.value < 0 || e.currentTarget.value == ''){changeFormData('children121', 0)}}}/>
                                                </label>
                                            </div>
                                            <div className="col-6 col-md-auto mb-2">
                                                <div className="d-flex flew-row flex-nowrap">
                                                    <label style={{maxWidth: '130px'}}>
                                                        {labels.numberofinfants}&nbsp;
                                                        <div className="tooltip-wrapper">
                                                            <span className="btn btn-info-modal">&#8505;</span>
                                                            <span className="tooltiptext">{labels.infantseatwarning}</span>
                                                        </div> 
                                                        <br />
                                                    </label>
                                                </div>
                                                <input type="number" className={inputErrors.includes('infants')?'is-invalid':''} min={0} value={form.infants} onChange={(e)=>{let temp = {...form}; if(e.currentTarget.value>=0 || e.currentTarget.value == ''){ temp.infants = e.currentTarget.value; } else {temp.infants = 0} setForm(temp)}} onBlur={(e)=>{if(e.currentTarget.value < 0 || e.currentTarget.value == ''){changeFormData('infants', 0)}}}/>
                                            </div>
                                        </div>
                                        <label className='highlighted'>
                                            {labels.lugage}
                                        </label>
                                        {inputErrors.some(err=> cargoVariables.includes(err))?
                                            <p className='text-danger'>{labels.cargoerror}</p>
                                        :
                                            ''
                                        }
                                        <div className="row">
                                            <div className="col-12">
                                                <p>
                                                    {labels.handlugageinfo}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">{labels.suitcaseswithouthandlugage}</label>
                                            </div>
                                            <div className="col-12 d-flex align-items-center mb-2">
                                                <input type="number" className={inputErrors.includes('suitcases')?'is-invalid':''} min={0} max={15} value={form.suitcases} onChange={(e)=>{if(checkAmountOfCargo(1) || form.suitcases > e.currentTarget.value){removeCargoErrors();} else if(!checkAmountOfCargo(1)){addCargoErrors()}  changeFormData('suitcases', e.currentTarget.value); }}/>
                                            </div>
                                        </div>
                                        <label className='highlighted'>
                                            {labels.extralugage}
                                        </label>
                                        <div className="row">
                                            <div className="col-6 col-lg-2 mb-2">
                                                <label htmlFor="">{labels.bikecases}</label>
                                                <input type="number" className={inputErrors.includes('bikecases')?'is-invalid':''} min={0} max={7} value={form.bikecases} onChange={(e)=>{if(checkAmountOfCargo(1) || form.bikecases > e.currentTarget.value){removeCargoErrors();} else if(!checkAmountOfCargo(1)){addCargoErrors()}  changeFormData('bikecases', e.currentTarget.value); }}/>
                                            </div>
                                            <div className="col-6 col-lg-2 mb-2">
                                                <label htmlFor="">{labels.babycarriages}</label>
                                                <input type="number" className={inputErrors.includes('babycarriages')?'is-invalid':''} min={0} max={7} value={form.babycarriages} onChange={(e)=>{if(checkAmountOfCargo(1) || form.babycarriages > e.currentTarget.value){removeCargoErrors();} else if(!checkAmountOfCargo(1)){addCargoErrors()}  changeFormData('babycarriages', e.currentTarget.value); }}/>
                                            </div>
                                            <div className="col-6 col-lg-2 mb-2">
                                                <label htmlFor="">{labels.wheelchairs}
                                                    <div className="tooltip-wrapper">
                                                        <span className="btn btn-info-modal">&#8505;</span>
                                                        <span className="tooltiptext">{labels.wheelchairswarning}</span>
                                                    </div>
                                                </label>
                                                <input type="number" className={inputErrors.includes('wheelchairs')?'is-invalid':''} min={0} max={7} value={form.wheelchairs} onChange={(e)=>{if(checkAmountOfCargo(1) || form.wheelchairs > e.currentTarget.value){removeCargoErrors();} else if(!checkAmountOfCargo(1)){addCargoErrors()}  changeFormData('wheelchairs', e.currentTarget.value); }}/>
                                            </div>
                                            <div className="col-6 col-lg-2 mb-2">
                                                <label htmlFor="">{labels.skies}</label>
                                                <input type="number" className={inputErrors.includes('skies')?'is-invalid':''} min={0} max={7} value={form.skies} onChange={(e)=>{if(checkAmountOfCargo(1) || form.skies > e.currentTarget.value){removeCargoErrors();} else if(!checkAmountOfCargo(1)){addCargoErrors()}  changeFormData('skies', e.currentTarget.value); }}/>
                                            </div>
                                            <div className="col-6 col-lg-2 mb-2">
                                                <label htmlFor="">{labels.rollators}</label>
                                                <input type="number" className={inputErrors.includes('rollators')?'is-invalid':''} min={0} max={7} value={form.rollators} onChange={(e)=>{if(checkAmountOfCargo(1) || form.rollators > e.currentTarget.value){removeCargoErrors();} else if(!checkAmountOfCargo(1)){addCargoErrors()}  changeFormData('rollators', e.currentTarget.value); }}/>
                                            </div>
                                            <div className="col-6 col-lg-2 mb-2">
                                                <label htmlFor="">{labels.golfbags}</label>
                                                <input type="number" className={inputErrors.includes('golfbags')?'is-invalid':''} min={0} max={7} value={form.golfbags} onChange={(e)=>{if(checkAmountOfCargo(1) || form.golfbags > e.currentTarget.value){removeCargoErrors();} else if(!checkAmountOfCargo(1)){addCargoErrors()}  changeFormData('golfbags', e.currentTarget.value); }}/>
                                            </div>
                                        </div>
                                        <hr className="dashed" />
                                        <div className="row mt-4">
                                            <div className="col-auto">
                                                <h4>
                                                    {labels.comments}
                                                </h4>
                                            </div>
                                            <div className="col-auto">
                                                <img src="/images/website/icons/Enveloppe.svg" alt="communication" className='flighticon'/>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-lg-12">
                                                <textarea className={inputErrors.includes('comments')?'is-invalid':''} value={form.comments} onChange={(e)=>{changeFormData('comments', e.currentTarget.value)}} rows="5" placeholder={capitalizeFirstLetter(labels.commentsplaceholder)}/>
                                            </div>
                                        </div>
                                    </>
                                }
                                {page == 1 && 
                                    <>
                                        <h4>
                                            {(isTravelAgent? labels.clientinfo : labels.personalinfo)}
                                        </h4>
                                        <div className="row">
                                            <div className="col-lg-6 mb-2">
                                                <label>
                                                    {labels.firstname}
                                                    <input type="text" className={inputErrors.includes('firstname')?'is-invalid':''} name='firstname' value={form.firstname} onChange={(e)=>{changeFormData('firstname', e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.firstname)}/>
                                                </label>
                                            </div>
                                            <div className="col-lg-6 mb-2">
                                                <label>
                                                    {labels.name}
                                                    <input type="text" className={inputErrors.includes('name')?'is-invalid':''} name='name' value={form.name} onChange={(e)=>{changeFormData('name', e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.name)}/>
                                                </label>
                                            </div>
                                            <div className="col-12 mb-2">
                                                <label>
                                                    {labels.email}
                                                    <input type="email" className={inputErrors.includes('email')?'is-invalid':''} name='email' value={form.email} onChange={(e)=>{changeFormData('email', e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.email)}/>
                                                </label>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <label>
                                                    {labels.phone}
                                                <InputMask mask="+99 (0) 999/99.99.99" formatChars={{'9': '[0-9]'}} alwaysShowMask={true} value={form.phone} onChange={(e)=>{changeFormData('phone', e.currentTarget.value)}} type="text" className={inputErrors.includes('phone')?'is-invalid':''}></InputMask>
                                                </label>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <label>
                                                    {labels.language}
                                                    <select name="language" value={form.language} onChange={(e)=>{changeFormData('language', e.currentTarget.value)}}>
                                                        <option value="nl">{labels.nl}</option>
                                                        <option value="en">{labels.en}</option>
                                                        <option value="fr">{labels.fr}</option>
                                                    </select>
                                                </label>
                                            </div>
                                            {(shouldPayImmediately && isTravelAgent) ?
                                                <div className="col-lg-6 mb-2">
                                                    <h5>
                                                        {labels.facturation_target}
                                                    </h5>
                                                    <select name="facturation_target" value={form.facturation_target} onChange={(e)=>{changeFormData('facturation_target', e.currentTarget.value)}}>
                                                        <option value="travelagency">{labels.travelagency}</option>
                                                        <option value="client">{labels.client}</option>
                                                    </select>
                                                </div>
                                                :
                                                <></>
                                            }
                                            {(shouldPayImmediately && (!isTravelAgent || form.facturation_target == 'client')) ? 
                                                <>
                                                    {
                                                        !isHotel && 
                                                        <div className="col-12 mb-2">
                                                            <label htmlFor="wantsSameFacturationAddress" className={inputErrors.includes('wantsSameFacturationAddress')?'is-invalid':''}>
                                                                <input type="checkbox" className='me-2' id='wantsSameFacturationAddress' checked={!form.wantsSameFacturationAddress} onChange={(e)=>{changeFormData('wantsSameFacturationAddress', !e.currentTarget.checked)}}/>
                                                                <span dangerouslySetInnerHTML={{ __html: labels.wantsSameFacturationAddress }}></span>
                                                            </label>
                                                        </div>
                                                    }
                                                    {!form.wantsSameFacturationAddress && 
                                                    <>
                                                        <div className="col-12 mb-2">
                                                            {labels.facturationaddress}
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <label>
                                                                {labels.company_name}
                                                                <input type="text"  className={inputErrors.includes('facturation_company_name')?'is-invalid':''} name='facturation_company_name' value={form.facturation_company_name} onChange={(e)=>{changeFormData('facturation_company_name', e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.company_name)}/>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <label>
                                                                {labels.vat}
                                                                <input type="text"  className={inputErrors.includes('facturation_vat')?'is-invalid':''} name='facturation_vat' value={form.facturation_vat} onChange={(e)=>{changeFormData('facturation_vat', e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.vat)}/>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-8 mb-2">
                                                            <label>
                                                                {labels.street}
                                                                <input type="text"  className={inputErrors.includes('facturation_street')?'is-invalid':''} name='facturation_street' value={form.facturation_street} onChange={(e)=>{changeFormData('facturation_street', e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.street)}/>
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-6 col-lg-2 mb-2">
                                                            <label>
                                                                {labels.street_nr}
                                                                <input type="text"  className={inputErrors.includes('facturation_street_nr')?'is-invalid':''} name='facturation_street_nr' value={form.facturation_street_nr} onChange={(e)=>{changeFormData('facturation_street_nr', e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.street_nr)}/>
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-6 col-lg-2 mb-2">
                                                            <label>
                                                                {labels.street_box}
                                                                <input type="text"  className={inputErrors.includes('facturation_street_box')?'is-invalid':''} name='facturation_street_box' value={form.facturation_street_box} onChange={(e)=>{changeFormData('facturation_street_box', e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.street_box)}/>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-3 mb-2">
                                                            <label>
                                                                {labels.zip}
                                                                <input type="text"  className={inputErrors.includes('facturation_zip')?'is-invalid':''} name='facturation_zip' value={form.facturation_zip} onChange={(e)=>{changeFormData('facturation_zip', e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.zip)}/>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-5 mb-2">
                                                            <label>
                                                                {labels.city}
                                                                <input type="text" className={inputErrors.includes('facturation_city')?'is-invalid':''} name='facturation_city' value={form.facturation_city} onChange={(e)=>{changeFormData('facturation_city', e.currentTarget.value)}} placeholder={capitalizeFirstLetter(labels.city)}/>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-4 mb-2">
                                                            <label>
                                                                {labels.selectcountry}
                                                                <select className={inputErrors.includes('facturation_country')?'is-invalid':''} name="facturation_country" value={form.facturation_country} onChange={(e)=>{changeFormData('facturation_country', e.currentTarget.value)}}>
                                                                    {facturationCountries && facturationCountries.length > 0 && facturationCountries.map((c)=>{
                                                                        return {value: c, label: countries['country_' + c]};
                                                                    }).sort(function(a, b) {
                                                                        const nameA = a.label.toUpperCase(); // ignore upper and lowercase
                                                                        const nameB = b.label.toUpperCase(); // ignore upper and lowercase
                                                                        if (nameA > nameB) {
                                                                        return 1;
                                                                        }
                                                                        if (nameA < nameB) {
                                                                        return -1;
                                                                        }
                                                                        return 0;
                                                                    }).map((c, i) => {
                                                                        return <option value={c.value} key={'country_' + i}>{c.label}</option>
                                                                    })}
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </>
                                                    }
                                                </>
                                                :
                                                <></>
                                            }
                                            <div className="col-12 mb-2">
                                                <label htmlFor="acceptprivacypolicy" className={inputErrors.includes('acceptprivacypolicy')?'is-invalid':''}>
                                                    <input type="checkbox" className='me-2' id='acceptprivacypolicy' checked={form.acceptprivacypolicy} onChange={(e)=>{changeFormData('acceptprivacypolicy', e.currentTarget.checked);}}/>
                                                    <span dangerouslySetInnerHTML={{ __html: labels.acceptprivacypolicyandtermsandconditions }}></span>
                                                </label>
                                            </div>
                                            <div className="col-12 mb-2">
                                                <label htmlFor="wantsads" className={inputErrors.includes('wantsads')?'is-invalid':''}>
                                                    <input type="checkbox" className='me-2' id='wantsads' checked={form.wantsads} onChange={(e)=>{changeFormData('wantsads', e.currentTarget.checked);}}/>
                                                    <span dangerouslySetInnerHTML={{ __html: isTravelAgent? labels.wantsads_travel_agent : labels.wantsads }}></span>
                                                </label>
                                            </div>
                                            {isTravelAgent && 
                                                <div className="col-12 mb-2">
                                                    <label htmlFor="acceptplaceorder" className={inputErrors.includes('acceptplaceorder')?'is-invalid':''}>
                                                        <input type="checkbox" className='me-2' id='acceptplaceorder' checked={form.acceptplaceorder} onChange={(e)=>{changeFormData('acceptplaceorder', e.currentTarget.checked)}}/>
                                                        <span dangerouslySetInnerHTML={{ __html: labels.acceptplaceorder }}></span>
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                    </>
                                }
                                {submitErrors && Object.keys(submitErrors).length > 0 && 
                                    <div className="row mt-3 mt-lg-0 align-items-center">
                                        {Object.keys(submitErrors).map((e)=>{
                                            return <div className="col-lg-11 text-danger">{submitErrors[e]}</div>
                                        })}
                                        
                                    </div>
                                }
                                <div className="row mt-3 align-items-center">
                                    {page > 0 &&
                                        <div className="col-auto">
                                            <button className='btn btn-navigation btn-previous' onClick={()=>{handlePageChange(page - 1)}}>{labels.previousstep}</button>
                                        </div>
                                    }
                                    <div className="col-auto ms-lg-3">
                                        {creatingOrder ?
                                            <div className='d-flex flex-row align-items-center'>
                                                <p className='mb-0 me-3'>
                                                    {!shouldPayImmediately?
                                                        <>
                                                            {labels.creatingorder}
                                                        </>
                                                        :
                                                        <>
                                                            {labels.preparingpayment}
                                                        </>
                                                    }
                                                </p>
                                                <div className="loadingspinner"></div>
                                            </div>
                                        :
                                        <>
                                            {(page == 1 && ((shareOptionAllowed('departure') && form.departureShared == "shared") || (shareOptionAllowed('return') && form.returnShared == "shared")))? 
                                                <button className='btn btn-navigation btn-next' data-toggle="modal" data-target="#share_confirmation_modal" onClick={(e)=>{if(!checkRequiredInputs(page)){e.preventDefault(); e.stopPropagation();}}}>{page == 1 ? (shouldPayImmediately ?  labels.topayment: labels.createorder ):labels.nextstep}</button>
                                            : 
                                                <button className='btn btn-navigation btn-next' onClick={(e)=>{e.preventDefault(); e.stopPropagation(); handlePageChange(page + 1)}}>{page == 1 ? (shouldPayImmediately ?  labels.topayment: labels.createorder ):labels.nextstep}</button>
                                            }
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className='overviewticket-wrapper'>
                                    <span className="overviewticket-title main-title d-none d-lg-block">
                                        {labels.yourtransfers}
                                    </span>
                                    <div className="overviewticket mt-2 mt-lg-0">
                                        <div className="overviewticket-contentblock d-lg-none" onClick={()=>{setShowTicket(!showTicket)}}>
                                            <div className="d-flex flex-row flex-nowrap align-items-center">
                                                <div className="">
                                                    <h4>
                                                        {labels.totalprice}
                                                    </h4>
                                                </div>
                                                {loadingPrice? 
                                                    <div className="ms-auto">
                                                        <div className="loadingspinner"></div>
                                                    </div>
                                                :
                                                <>
                                                    {(departurePrice || returnPrice)?
                                                        <div className="ms-auto">
                                                            {flatDiscount?
                                                                <div className="text-start">
                                                                    {
                                                                        '€'+(Math.round(( departurePrice + returnPrice - totalDiscount()) * 100) / 100).toFixed(2)
                                                                    }
                                                                </div>
                                                            : 
                                                                <div className="ms-auto">
                                                                    <p></p>
                                                                </div>
                                                            }
                                                        </div>
                                                    : 
                                                        <div className="ms-auto">
                                                            <p></p>
                                                        </div>
                                                    }
                                                </>
                                                }
                                                <div className="overviewticket-dropdown"></div>
                                            </div>
                                        </div>
                                        <div className="overviewticket-collapse" style={{maxHeight: width > 992? '100%' :((width > 992 || showTicket)?'100vh':0)}}>
                                            <hr className='dashed d-lg-none'/>
                                            <div className="overviewticket-contentblock">
                                                <span className="overviewticket-title mb-2 d-lg-none">
                                                    {labels.yourtransfers}
                                                </span>
                                                {(form.wantsDepartureTransfer && !form.departureFlowFromAirport && ((form.full_address && form.full_address != '') || (form.departureAirport && form.departureAirport))) && 
                                                    <>
                                                        <h4>{labels.departure}</h4>
                                                        <div className="row align-items-center">
                                                            {isHotel?
                                                                <>
                                                                    <div className="col-9 col-md-10 mb-1">
                                                                        <h5 className='m-0'>
                                                                            {(form.departureAirport) &&
                                                                                <>
                                                                                    {destinations.filter((d)=>{ return d.destination_code == form.departureAirport})[0].destination_name}
                                                                                </>
                                                                            }
                                                                        </h5>
                                                                    </div>
                                                                    <div className="col-3 col-md-2 d-flex mb-1">
                                                                        <img src="/images/website/icons/Vliegtuig.svg" alt="airplane" className='overviewticket-icon my-auto'/>
                                                                    </div>
                                                                </>
                                                            :
                                                                <>
                                                                {(form.full_address && form.full_address != '') && 
                                                                    <>
                                                                        <div className="col-9 col-md-10 mb-1">
                                                                            <h5 className='m-0'>
                                                                                {form.full_address}
                                                                            </h5>
                                                                        </div>
                                                                        <div className="col-3 col-md-2 d-flex mb-1">
                                                                            <img src="/images/website/icons/Auto.svg" alt="car" className='overviewticket-icon my-auto'/>
                                                                        </div>
                                                                    </>
                                                                }
                                                                </>
                                                            }
                                                            {/* stopovers are not used for now */}
                                                            {form.departure_stopovers.map((s, i)=>{
                                                                return <div className='col-12' key={'dep-stopover-' + i}>
                                                                    {(s.street && (s.zip || s.city)) && 
                                                                        <div className='row'>
                                                                            <div className="col-9 col-md-10 mb-1">
                                                                                <h5 className='m-0'>
                                                                                    {s.street} {s.street_nr}{s.street_box}, <span className='d-inline-block'>{s.zip} {s.city}</span> 
                                                                                </h5>
                                                                            </div>
                                                                            <div className="col-3 col-md-2 d-flex mb-1">
                                                                                <img src="/images/website/icons/Auto.svg" alt="car" className='overviewticket-icon my-auto'/>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            })}
                                                            {isHotel?
                                                                <>
                                                                    <div className="col-9 col-md-10">
                                                                        <h5 className='m-0'>
                                                                            {form.full_address}
                                                                        </h5>
                                                                    </div>
                                                                    <div className="col-3 col-md-2 d-flex">
                                                                        <img src="/images/website/icons/Bagage.svg" alt="car" className='overviewticket-icon my-auto'/>
                                                                    </div>
                                                                </>
                                                                :      
                                                                <>
                                                                {(form.departureAirport && form.departureAirport != '') && 
                                                                    <>
                                                                        <div className="col-9 col-md-10">
                                                                            <h5 className='m-0'>
                                                                                {(form.departureAirport) &&
                                                                                    <>
                                                                                        {destinations.filter((d)=>{ return d.destination_code == form.departureAirport})[0].destination_name}
                                                                                    </>
                                                                                }
                                                                            </h5>
                                                                        </div>
                                                                        <div className="col-3 col-md-2 d-flex">
                                                                            <img src="/images/website/icons/Vliegtuig.svg" alt="airplane" className='overviewticket-icon my-auto'/>
                                                                        </div>
                                                                    </>
                                                                }
                                                                </>
                                                            }
                                                        </div>
                                                    </>
                                                }
                                                {(form.wantsDepartureTransfer && form.departureFlowFromAirport && ((form.returnAirport && form.returnAirport != '') || (form.dropoff_full_address && form.dropoff_full_address != ''))) && 
                                                    <>
                                                        <h4>{labels.departure}</h4>
                                                        <div className="row align-items-center">
                                                            {isHotel?
                                                            <>
                                                                <div className="col-9 col-md-10 mb-1">
                                                                    <h5 className='m-0'>
                                                                        {form.dropoff_full_address}
                                                                    </h5>
                                                                </div>
                                                                <div className="col-3 col-md-2 mb-1">
                                                                    <img src="/images/website/icons/Bagage.svg" alt="car" className='overviewticket-icon'/>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                            {(form.returnAirport && form.returnAirport != '') && 
                                                                <>
                                                                    <div className="col-9 col-md-10 mb-1">
                                                                        <h5 className='m-0'>
                                                                            {(form.returnAirport) &&
                                                                                <>
                                                                                    {destinations.filter((d)=>{ return d.destination_code == form.returnAirport})[0].destination_name}
                                                                                </>
                                                                            }
                                                                        </h5>
                                                                    </div>
                                                                    <div className="col-3 col-md-2">
                                                                        <img src="/images/website/icons/Vliegtuig.svg" alt="airplane" className='overviewticket-icon icon-reverse'/>
                                                                    </div>
                                                                </>
                                                            }
                                                            </>
                                                            }
                                                            {/* stopovers are not used for now */}
                                                            {form.return_stopovers.map((s, i)=>{
                                                                return <div className='col-12' key={'ret-stopover-' + i}>
                                                                    {(s.street && (s.zip || s.city)) && 
                                                                        <div className='row'>
                                                                            <div className="col-9 col-md-10 mb-1">
                                                                                <h5 className='m-0'>
                                                                                    {s.street} {s.street_nr}{s.street_box}, <span className='d-inline-block'>{s.zip} {s.city}</span> 
                                                                                </h5>
                                                                            </div>
                                                                            <div className="col-3 col-md-2 d-flex mb-1">
                                                                                <img src="/images/website/icons/Auto.svg" alt="car" className='overviewticket-icon icon-reverse my-auto'/>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            })}
                                                            {isHotel?
                                                                <>
                                                                    <div className="col-9 col-md-10 mb-1">
                                                                        <h5 className='m-0'>
                                                                            {(form.returnAirport) &&
                                                                                <>
                                                                                    {destinations.filter((d)=>{ return d.destination_code == form.returnAirport})[0].destination_name}
                                                                                </>
                                                                            }
                                                                        </h5>
                                                                    </div>
                                                                    <div className="col-3 col-md-2">
                                                                        <img src="/images/website/icons/Vliegtuig.svg" alt="airplane" className='overviewticket-icon icon-reverse'/>
                                                                    </div>
                                                                </>
                                                            :
                                                                <>
                                                                    {(form.dropoff_full_address && form.dropoff_full_address != '') && 
                                                                        <>
                                                                            <div className="col-9 col-md-10 mb-1">
                                                                                <h5 className='m-0'>
                                                                                    {form.dropoff_full_address}
                                                                                </h5>
                                                                            </div>
                                                                            <div className="col-3 col-md-2">
                                                                                <img src="/images/website/icons/Auto.svg" alt="car" className='overviewticket-icon icon-reverse'/>
                                                                            </div>    
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    </>
                                                }
                                                {(form.wantsDepartureTransfer && ((form.full_address && form.full_address != '') || (form.departureAirport && form.departureAirport))) && (form.wantsReturnTransfer && ((form.returnAirport && form.returnAirport != '') || (form.dropoff_full_address && form.dropoff_full_address != ''))) && 
                                                    <div className="mt-2"></div>
                                                }
                                                {(form.wantsReturnTransfer && !form.returnFlowFromAirport && ((form.full_address && form.full_address != '') || (form.departureAirport && form.departureAirport))) && 
                                                    <>
                                                        <h4>{labels.return}</h4>
                                                        <div className="row align-items-center">
                                                            {isHotel?
                                                                <>
                                                                    <div className="col-9 col-md-10 mb-1">
                                                                        <h5 className='m-0'>
                                                                            {(form.departureAirport) &&
                                                                                <>
                                                                                    {destinations.filter((d)=>{ return d.destination_code == form.departureAirport})[0].destination_name}
                                                                                </>
                                                                            }
                                                                        </h5>
                                                                    </div>
                                                                    <div className="col-3 col-md-2 d-flex mb-1">
                                                                        <img src="/images/website/icons/Vliegtuig.svg" alt="airplane" className='overviewticket-icon my-auto'/>
                                                                    </div>
                                                                </>
                                                            :
                                                                <>
                                                                {(form.full_address && form.full_address != '') && 
                                                                    <>
                                                                        <div className="col-9 col-md-10 mb-1">
                                                                            <h5 className='m-0'>
                                                                                {form.full_address}
                                                                            </h5>
                                                                        </div>
                                                                        <div className="col-3 col-md-2 d-flex mb-1">
                                                                            <img src="/images/website/icons/Auto.svg" alt="car" className='overviewticket-icon my-auto'/>
                                                                        </div>
                                                                    </>
                                                                }
                                                                </>
                                                            }
                                                            {/* stopovers are not used for now */}
                                                            {form.departure_stopovers.map((s, i)=>{
                                                                return <div className='col-12' key={'dep-stopover-' + i}>
                                                                    {(s.street && (s.zip || s.city)) && 
                                                                        <div className='row'>
                                                                            <div className="col-9 col-md-10 mb-1">
                                                                                <h5 className='m-0'>
                                                                                    {s.street} {s.street_nr}{s.street_box}, <span className='d-inline-block'>{s.zip} {s.city}</span> 
                                                                                </h5>
                                                                            </div>
                                                                            <div className="col-3 col-md-2 d-flex mb-1">
                                                                                <img src="/images/website/icons/Auto.svg" alt="car" className='overviewticket-icon my-auto'/>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            })}
                                                            {isHotel?
                                                                <>
                                                                    <div className="col-9 col-md-10">
                                                                        <h5 className='m-0'>
                                                                            {form.full_address}
                                                                        </h5>
                                                                    </div>
                                                                    <div className="col-3 col-md-2 d-flex">
                                                                        <img src="/images/website/icons/Bagage.svg" alt="car" className='overviewticket-icon my-auto'/>
                                                                    </div>
                                                                </>
                                                                :      
                                                                <>
                                                                {(form.departureAirport && form.departureAirport != '') && 
                                                                    <>
                                                                        <div className="col-9 col-md-10">
                                                                            <h5 className='m-0'>
                                                                                {(form.departureAirport) &&
                                                                                    <>
                                                                                        {destinations.filter((d)=>{ return d.destination_code == form.departureAirport})[0].destination_name}
                                                                                    </>
                                                                                }
                                                                            </h5>
                                                                        </div>
                                                                        <div className="col-3 col-md-2 d-flex">
                                                                            <img src="/images/website/icons/Vliegtuig.svg" alt="airplane" className='overviewticket-icon my-auto'/>
                                                                        </div>
                                                                    </>
                                                                }
                                                                </>
                                                            }
                                                        </div>
                                                    </>
                                                }
                                                {(form.wantsReturnTransfer && form.returnFlowFromAirport && ((form.returnAirport && form.returnAirport != '') || (form.dropoff_full_address && form.dropoff_full_address != ''))) && 
                                                    <>
                                                        <h4>{labels.return}</h4>
                                                        <div className="row align-items-center">
                                                            {isHotel?
                                                            <>
                                                                <div className="col-9 col-md-10 mb-1">
                                                                    <h5 className='m-0'>
                                                                        {form.dropoff_full_address}
                                                                    </h5>
                                                                </div>
                                                                <div className="col-3 col-md-2 mb-1">
                                                                    <img src="/images/website/icons/Bagage.svg" alt="car" className='overviewticket-icon'/>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                            {(form.returnAirport && form.returnAirport != '') && 
                                                                <>
                                                                    <div className="col-9 col-md-10 mb-1">
                                                                        <h5 className='m-0'>
                                                                            {(form.returnAirport) &&
                                                                                <>
                                                                                    {destinations.filter((d)=>{ return d.destination_code == form.returnAirport})[0].destination_name}
                                                                                </>
                                                                            }
                                                                        </h5>
                                                                    </div>
                                                                    <div className="col-3 col-md-2">
                                                                        <img src="/images/website/icons/Vliegtuig.svg" alt="airplane" className='overviewticket-icon icon-reverse'/>
                                                                    </div>
                                                                </>
                                                            }
                                                            </>
                                                            }
                                                            {/* stopovers are not used for now */}
                                                            {form.return_stopovers.map((s, i)=>{
                                                                return <div className='col-12' key={'ret-stopover-' + i}>
                                                                    {(s.street && (s.zip || s.city)) && 
                                                                        <div className='row'>
                                                                            <div className="col-9 col-md-10 mb-1">
                                                                                <h5 className='m-0'>
                                                                                    {s.street} {s.street_nr}{s.street_box}, <span className='d-inline-block'>{s.zip} {s.city}</span> 
                                                                                </h5>
                                                                            </div>
                                                                            <div className="col-3 col-md-2 d-flex mb-1">
                                                                                <img src="/images/website/icons/Auto.svg" alt="car" className='overviewticket-icon icon-reverse my-auto'/>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            })}
                                                            {isHotel?
                                                                <>
                                                                    <div className="col-9 col-md-10 mb-1">
                                                                        <h5 className='m-0'>
                                                                            {(form.returnAirport) &&
                                                                                <>
                                                                                    {destinations.filter((d)=>{ return d.destination_code == form.returnAirport})[0].destination_name}
                                                                                </>
                                                                            }
                                                                        </h5>
                                                                    </div>
                                                                    <div className="col-3 col-md-2">
                                                                        <img src="/images/website/icons/Vliegtuig.svg" alt="airplane" className='overviewticket-icon icon-reverse'/>
                                                                    </div>
                                                                </>
                                                            :
                                                                <>
                                                                    {(form.dropoff_full_address && form.dropoff_full_address != '') && 
                                                                        <>
                                                                            <div className="col-9 col-md-10 mb-1">
                                                                                <h5 className='m-0'>
                                                                                    {form.dropoff_full_address}
                                                                                </h5>
                                                                            </div>
                                                                            <div className="col-3 col-md-2">
                                                                                <img src="/images/website/icons/Auto.svg" alt="car" className='overviewticket-icon icon-reverse'/>
                                                                            </div>    
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    </>
                                                }
    
                                                <label className='mt-2'>
                                                    {printPaxData()}{cargo && <>&nbsp;{labels.with}&nbsp;{cargo}</>}
                                                </label>
    
                                                {/* {((cargo || childseats)) && 
                                                    <div className="row mt-2">
                                                        <div className="col-12">
                                                            <span className="overviewticket-title">
                                                                {labels.lugage}
                                                            </span>
                                                        </div>
                                                        {cargo && 
                                                            <div className="col-12">
                                                                <h5>
                                                                    {cargo}
                                                                </h5>
                                                            </div>
                                                        }
                                                        {childseats && 
                                                            <div className="col-12">
                                                                <h5>
                                                                    {labels.weprovide}: {childseats}
                                                                </h5>
                                                            </div>
                                                        }
                                                    </div>
                                                } */}
                                                {(form.firstname || form.name || form.email || form.phone) && 
                                                    <div className="row mt-2">
                                                        <div className="col-12">
                                                            <span className="overviewticket-title mb-2">
                                                                {labels.personalinfo}
                                                            </span>
                                                        </div>
                                                        {(form.firstname || form.name) && 
                                                            <div className="col-12">
                                                                <h5>
                                                                    {form.firstname} {form.name}
                                                                </h5>
                                                            </div>
                                                        }
                                                        {form.email && 
                                                            <div className="col-12">
                                                                <h5>
                                                                    {form.email}
                                                                </h5>
                                                            </div>
                                                        }
                                                        {form.phone && 
                                                            <div className="col-12">
                                                                <h5>
                                                                    {form.phone}
                                                                </h5>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            <hr className='dashed'/>
                                            <div className="overviewticket-contentblock">
                                                <span className="overviewticket-title mb-2">
                                                    {labels.price}
                                                </span>
                                                {form.wantsDepartureTransfer && !form.departureFlowFromAirport && 
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h5>
                                                                {labels.departuretransport}
                                                                {shareOptionAllowed('departure')?
                                                                    ((form.departureShared == 'shared') ?
                                                                        <span className='transfer-shared-label'>{labels.transfer_shared}</span>
                                                                    :
                                                                        <span className='transfer-shared-label'>{labels.transfer_individual}</span>)
                                                                :
                                                                ''
                                                                }
                                                            </h5>
                                                        </div>
                                                        {loadingPrice? 
                                                            <div className="col-auto ms-auto">
                                                                <div className="loadingspinner"></div>
                                                            </div>
                                                        :
                                                        <>
                                                            {departurePrice?
                                                                <div className="col-auto ms-auto text-start">
                                                                    {
                                                                        '€'+(Math.round((form.departureShared === "private" || !shareDiscountApplicable('departure') ?departurePrice: departurePriceShared) * 100) / 100).toFixed(2)
                                                                    }
                                                                    {
                                                                        ((departureDiscount && departureDiscount.explanation) && (form.departureShared === "private" || !shareDiscountApplicable('departure') )) && 
                                                                            <span className='text-danger'>
                                                                                {departureDiscount.explanation}
                                                                            </span>
                                                                    }
                                                                    {
                                                                        ((departureDiscountShared && departureDiscountShared.explanation) && form.departureShared === "shared" && shareDiscountApplicable('departure')) && 
                                                                            <span className='text-danger'>
                                                                                {departureDiscountShared.explanation}
                                                                            </span>
                                                                    }
                                                                </div>
                                                            : 
                                                                <div className="col-xl-6 ms-auto">
                                                                    <p>{labels.fillinaddressandairports}</p>
                                                                </div>
                                                            }
                                                        </>
                                                        }
                                                    </div>
                                                }
                                                {form.wantsDepartureTransfer && form.departureFlowFromAirport && 
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h5>
                                                                {labels.departuretransport}
                                                                {shareOptionAllowed('return') ? 
                                                                    ((form.returnShared == 'shared') ?
                                                                        <span className='transfer-shared-label'>{labels.transfer_shared}</span>
                                                                    :
                                                                        <span className='transfer-shared-label'>{labels.transfer_individual}</span>)
                                                                : 
                                                                ''
                                                                }
    
                                                            </h5>
                                                        </div>
                                                        {loadingPrice? 
                                                            <div className="col-auto ms-auto">
                                                                <div className="loadingspinner"></div>
                                                            </div>
                                                        :
                                                        <>
                                                            {returnPrice?
                                                                <div className="col-auto ms-auto text-start">
                                                                    {
                                                                        '€'+(Math.round((form.returnShared === "private" || !shareDiscountApplicable('return') ?returnPrice: returnPriceShared) * 100) / 100).toFixed(2)
                                                                    }
                                                                    {
                                                                        ((returnDiscount && returnDiscount.explanation) && (form.returnShared === "private" || !shareDiscountApplicable('return') )) && 
                                                                            <span className='text-danger'>
                                                                                {returnDiscount.explanation}
                                                                            </span>
                                                                    }
                                                                    {
                                                                        ((returnDiscountShared && returnDiscountShared.explanation) && form.returnShared === "shared" && shareDiscountApplicable('return')) && 
                                                                            <span className='text-danger'>
                                                                                {returnDiscountShared.explanation}
                                                                            </span>
                                                                    }
                                                                </div>
                                                            : 
                                                                <div className="col-xl-6 ms-auto">
                                                                    <p>{labels.fillinaddressandairports}</p>
                                                                </div>
                                                            }
                                                        </>
                                                        }
                                                    </div>
                                                }
                                                {form.wantsReturnTransfer && !form.returnFlowFromAirport && 
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h5>
                                                                {labels.returntransport}
                                                                {shareOptionAllowed('departure')?
                                                                    ((form.departureShared == 'shared') ?
                                                                        <span className='transfer-shared-label'>{labels.transfer_shared}</span>
                                                                    :
                                                                        <span className='transfer-shared-label'>{labels.transfer_individual}</span>)
                                                                :
                                                                ''
                                                                }
                                                            </h5>
                                                        </div>
                                                        {loadingPrice? 
                                                            <div className="col-auto ms-auto">
                                                                <div className="loadingspinner"></div>
                                                            </div>
                                                        :
                                                        <>
                                                            {departurePrice?
                                                                <div className="col-auto ms-auto text-start">
                                                                    {
                                                                        '€'+(Math.round((form.departureShared === "private" || !shareDiscountApplicable('departure') ?departurePrice: departurePriceShared) * 100) / 100).toFixed(2)
                                                                    }
                                                                    {
                                                                        ((departureDiscount && departureDiscount.explanation) && (form.departureShared === "private" || !shareDiscountApplicable('departure') )) && 
                                                                            <span className='text-danger'>
                                                                                {departureDiscount.explanation}
                                                                            </span>
                                                                    }
                                                                    {
                                                                        ((departureDiscountShared && departureDiscountShared.explanation) && form.departureShared === "shared" && shareDiscountApplicable('departure')) && 
                                                                            <span className='text-danger'>
                                                                                {departureDiscountShared.explanation}
                                                                            </span>
                                                                    }
                                                                </div>
                                                            : 
                                                                <div className="col-xl-6 ms-auto">
                                                                    <p>{labels.fillinaddressandairports}</p>
                                                                </div>
                                                            }
                                                        </>
                                                        }
                                                    </div>
                                                }
                                                {form.wantsReturnTransfer && form.returnFlowFromAirport && 
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <h5>
                                                                {labels.returntransport}
                                                                {shareOptionAllowed('return') ? 
                                                                    ((form.returnShared == 'shared') ?
                                                                        <span className='transfer-shared-label'>{labels.transfer_shared}</span>
                                                                    :
                                                                        <span className='transfer-shared-label'>{labels.transfer_individual}</span>)
                                                                : 
                                                                ''
                                                                }
    
                                                            </h5>
                                                        </div>
                                                        {loadingPrice? 
                                                            <div className="col-auto ms-auto">
                                                                <div className="loadingspinner"></div>
                                                            </div>
                                                        :
                                                        <>
                                                            {returnPrice?
                                                                <div className="col-auto ms-auto text-start">
                                                                    {
                                                                        '€'+(Math.round((form.returnShared === "private" || !shareDiscountApplicable('return') ?returnPrice: returnPriceShared) * 100) / 100).toFixed(2)
                                                                    }
                                                                    {
                                                                        ((returnDiscount && returnDiscount.explanation) && (form.returnShared === "private" || !shareDiscountApplicable('return') )) && 
                                                                            <span className='text-danger'>
                                                                                {returnDiscount.explanation}
                                                                            </span>
                                                                    }
                                                                    {
                                                                        ((returnDiscountShared && returnDiscountShared.explanation) && form.returnShared === "shared" && shareDiscountApplicable('return')) && 
                                                                            <span className='text-danger'>
                                                                                {returnDiscountShared.explanation}
                                                                            </span>
                                                                    }
                                                                </div>
                                                            : 
                                                                <div className="col-xl-6 ms-auto">
                                                                    <p>{labels.fillinaddressandairports}</p>
                                                                </div>
                                                            }
                                                        </>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            <hr />
                                            <div className="overviewticket-contentblock">
                                                <div className="row">
                                                    {((departurePrice || returnPrice) && !loadingPrice)?
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <h5>
                                                                        {labels.total_discount}
                                                                    </h5>
                                                                </div>
                                                                {totalShareDiscount() > 0
                                                                &&
                                                                    <>
                                                                        <div className="col-12">
                                                                            <div className="row d-flex flex-row align-items-center">
                                                                                <div className="col-auto">
                                                                                    <p className='m-0'>
                                                                                        {labels.share_discount}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-auto ms-auto">
                                                                                    {'- €'+(Math.round(totalShareDiscount()*100)/ 100).toFixed(2)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                                <div className="col-12 mb-2">
                                                                    <div className="row d-flex flex-row align-items-center">
                                                                        <div className="col-auto">
                                                                            <p className='mt-0 mb-1'>
                                                                                {labels.couponcode}
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-auto ms-auto">
                                                                            {totalCouponDiscount() > 0 ? '- €'+(Math.round(totalCouponDiscount()*100)/ 100).toFixed(2) : ''}
                                                                        </div>
                                                                        <div className="col-12"></div>
                                                                        <div className="col-7">
                                                                            <input type="text" className='input-coupon' id='input-coupon' defaultValue={form.couponcode} placeholder={capitalizeFirstLetter(labels.coupon_discount)} onKeyUp={(e)=>{
                                                                                clearTimeout(couponTimer);
                                                                                let value = e.currentTarget.value;
                                                                                couponTimer = setTimeout(() => {
                                                                                    changeFormData('couponcode', value);
                                                                                }, 1500);
                                                                            }}/>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            {(!loadingPrice && !validCode && form.couponcode != '')&& 
                                                                                <p className='text-danger'>
                                                                                    {labels.invalidcouponcode}
                                                                                </p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    : 
                                                        ''
                                                    }
                                                    <div className="col-auto">
                                                        <h5>
                                                            {labels.totalprice}
                                                        </h5>
                                                    </div>
                                                    {loadingPrice? 
                                                        <div className="col-auto ms-auto">
                                                            <div className="loadingspinner"></div>
                                                        </div>
                                                    :
                                                    <>
                                                        {(departurePrice || returnPrice)?
                                                            <div className="col-auto ms-auto">
                                                                {flatDiscount?
                                                                    <div className="text-start">
                                                                        {
                                                                            '€'+(Math.round(( departurePrice + returnPrice - totalDiscount()) * 100) / 100).toFixed(2)
                                                                        }
                                                                    </div>
                                                                : 
                                                                    <div className="col-xl-6 ms-auto">
                                                                        <p>{labels.fillinaddressandairports}</p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        : 
                                                            <div className="col-xl-6 ms-auto">
                                                                <p>{labels.fillinaddressandairports}</p>
                                                            </div>
                                                        }
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center overviewticket-pagination">
                                        {page > 0 &&
                                            <div className="col-auto">
                                                <button className='btn btn-navigation btn-previous' onClick={()=>{handlePageChange(page - 1)}}>{labels.previousstep}</button>
                                            </div>
                                        }
                                        <div className="col-auto ms-auto pe-0">
                                            {creatingOrder ?
                                                <div className='d-flex flex-row align-items-center'>
                                                    <p className='mb-0 me-3'>
                                                        {!shouldPayImmediately?
                                                            <>
                                                                {labels.creatingorder}
                                                            </>
                                                            :
                                                            <>
                                                                {labels.preparingpayment}
                                                            </>
                                                        }
                                                    </p>
                                                    <div className="loadingspinner"></div>
                                                </div>
                                            :
                                            <>
                                                {(page == 1 && ((shareOptionAllowed('departure') && form.departureShared == "shared") || (shareOptionAllowed('return') && form.returnShared == "shared")))? 
                                                    <button className='btn btn-navigation btn-next' data-toggle="modal" data-target="#share_confirmation_modal" onClick={(e)=>{if(!checkRequiredInputs(page)){e.preventDefault(); e.stopPropagation();}}}>{page == 1 ? (shouldPayImmediately ?  labels.topayment: labels.createorder ):labels.nextstep}</button>
                                                : 
                                                    <button className='btn btn-navigation btn-next' onClick={(e)=>{e.preventDefault(); e.stopPropagation(); handlePageChange(page + 1)}}>{page == 1 ? (shouldPayImmediately ?  labels.topayment: labels.createorder ):labels.nextstep}</button>
                                                }
                                            </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade share-modal" id="share_info_modal" tabIndex="-1" role="dialog" aria-labelledby="share_info_modal" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">{labels.share_info_modal_title}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>
                                        {labels.share_info_modal_intro}
                                    </p>
                                    <ul>
                                        <li dangerouslySetInnerHTML={{ __html: labels.share_info_modal_advantage_1 }}></li>
                                        <li dangerouslySetInnerHTML={{ __html: labels.share_info_modal_advantage_2 }}></li>
                                        <li dangerouslySetInnerHTML={{ __html: labels.share_info_modal_advantage_3 }}></li>
                                        <li dangerouslySetInnerHTML={{ __html: labels.share_info_modal_advantage_4 }}></li>
                                    </ul>
                                    <p dangerouslySetInnerHTML={{ __html: labels.share_info_modal_readmore }}></p>
                                    <div style={{padding:"75% 0 0 0",position:"relative"}}>
                                        <iframe 
                                            src="https://player.vimeo.com/video/1024797431?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                            frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
                                            style={{position:"absolute",top:0,left:0,width:'100%',height:"100%"}} title="COMOBI"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade share-modal" id="share_confirmation_modal" tabIndex="-1" role="dialog" aria-labelledby="share_confirmation_modal" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">{labels.share_confirmation_modal_title}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <ul>
                                        <li dangerouslySetInnerHTML={{ __html: labels.share_info_modal_advantage_1 }}></li>
                                        <li dangerouslySetInnerHTML={{ __html: labels.share_info_modal_advantage_2 }}></li>
                                        <li dangerouslySetInnerHTML={{ __html: labels.share_info_modal_advantage_3 }}></li>
                                        <li dangerouslySetInnerHTML={{ __html: labels.share_info_modal_advantage_4 }}></li>
                                    </ul>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" aria-label="Close" onClick={()=>{handlePageChange(page - 1)}}>
                                        {labels.change}
                                    </button>
                                    {creatingOrder ?
                                            <div className='d-flex flex-row align-items-center'>
                                                <p className='mb-0 me-3'>
                                                    {!shouldPayImmediately?
                                                        <>
                                                            {labels.creatingorder}
                                                        </>
                                                        :
                                                        <>
                                                            {labels.preparingpayment}
                                                        </>
                                                    }
                                                </p>
                                                <div className="loadingspinner"></div>
                                            </div>
                                        :
                                        <>
                                      <button type="button" className="btn btn-secondary" onClick={()=>{handlePageChange(page + 1)}}>{labels.continue}</button>

                                        </>
                                        }
                                </div>
                            </div>
                        </div>
                    </div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                </>
            }
        </>
    );
}

export default ReservationPage;

if (document.getElementsByClassName('reservationmodule').length > 0) {
    Array.from(document.getElementsByClassName('reservationmodule')).forEach(el => {
        var data = el.getAttribute('data');
        ReactDOM.render(<ReservationPage data={data} />, el);
    });
}
