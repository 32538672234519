import React, {useLayoutEffect, useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {checkPayment, retryPayment} from '../utilities/Utilities';

function CheckPayment(props) {
    const [paymentUuid, setPaymentUuid] = useState();
    const [paymentStatus, setPaymentStatus] = useState('open');
    const [labels, setLabels] = useState();
    const [retryingPayment, setRetryingPayment] = useState(false);
    const [returnUrl, setReturnUrl] = useState(false);
    const [tuiVip, setTuiVip] = useState(false);

    useEffect(async () => {
        if (paymentUuid) {
            checkPaymentByInterval(paymentUuid);
        }
    }, [paymentUuid]);

    useLayoutEffect(() => {
        let temp = JSON.parse(props.data);
        setPaymentUuid(temp?.paymentUuid);
        setReturnUrl(temp?.returnUrl??'/');
        setTuiVip(temp?.TuiVip??false);
        setLabels(temp?.labels??{});
        let elements = document.getElementsByClassName('checkpayment');
        for (let el of elements) {
            el.removeAttribute('data');
        }
    }, []);

    const checkPaymentByInterval = async (uuid) => {
        let resp = await checkPayment(uuid);
        if (resp.ok) {
            let json= await resp.json();
            setPaymentStatus(json.status);
            if (json.status === 'open') {
                window.setTimeout(() => {
                    checkPaymentByInterval(uuid);
                }, 5000);
            }
        }
    }

    const handleClickRetryPayment= async () => {
        setRetryingPayment(true);
        let resp = await retryPayment(paymentUuid, tuiVip);
        if (resp.ok) {
            let json = await resp.json();
            window.location.href = json.redirectUrl;
        }
    }

    return (
        <>
            {labels && 
                <>
                    {paymentStatus == 'open' && 
                    <div className="d-flex flex-row alig-items-center">
                        <h3 className='m-0 mr-3'>
                            {labels.awaitingpaymenttitle}!
                        </h3>
                        <div className="loadingspinner my-auto"></div>
                    </div>
                    }
                    {paymentStatus == 'paid' && 
                        <>
                            <h3>
                                {labels.paymentsuccesstitle}!
                            </h3>
                            <p dangerouslySetInnerHTML={{__html: labels.paymentsuccessmessage}}>
                            </p>
                            <div className="d-flex flex-row alig-items-center justify-content-around mt-3">
                                <a href={returnUrl} className='btn btn-primary ms-auto'>{labels.returntohome}</a>
                            </div>
                        </>
                    }
                    {paymentStatus == 'failed' && 
                        <>
                            <h3>
                                {labels.paymentfailedtitle}
                            </h3>
                            <p dangerouslySetInnerHTML={{__html: labels.paymentfailedmessage}}>
                            </p>
                            <div className="d-flex flex-row alig-items-center justify-content-around mt-3">
                                <a href={returnUrl} className='btn btn-primary mx-3'>{labels.cancelorder}</a>
                                {retryingPayment ?
                                    <div className='d-flex flex-row align-items-center'>
                                        <p className='mb-0 me-3'>
                                            {labels.preparingpayment}
                                        </p>
                                        <div className="loadingspinner"></div>
                                    </div>
                                :
                                <button className='btn btn-primary btn-primary-reverse mx-3' onClick={()=>{handleClickRetryPayment()}}>{labels.retrypayment}</button>
                            }
                            </div>
                        </>
                    }
                </>
            }
        </>
    );
}

export default CheckPayment;

if (document.getElementsByClassName('checkpayment').length > 0) {
    Array.from(document.getElementsByClassName('checkpayment')).forEach(el => {
        var data = el.getAttribute('data');
        ReactDOM.render(<CheckPayment data={data} />, el);
    });
}
