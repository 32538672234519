import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';

function Searchbar(props) {
    const [localSearchString, setlocalSearchString] = useState('');
    const [label, setLabel] = useState('');
    const [route, setRoute] = useState('');
    const [urlParams, setUrlParams] = useState('');

    useEffect(()=>{
        let temp = JSON.parse(props.data);
        setLabel(temp.label??'search');
        setlocalSearchString(temp.search??'');
        setRoute(temp.route??'');
        setUrlParams(temp.urlParams??[]);
    }, []);

    const handleFilter = async () => {
        let newRoute = `${route}?search=${localSearchString}`;
        Object.entries(urlParams).forEach((p) => {
            if (p[0] != 'search') {
                newRoute += `&${p[0]}=${p[1]}`;
            }
        });
        window.location = newRoute;
    }

    return (
        <div className="search-container ms-auto">
            <label htmlFor='search-input' className="search-label">
                {label}:
            </label>
            <input id='search-input' className="search-input ms-1 w-100" onKeyDown={(e)=>{
                    if (e.key === 'Enter') {
                        handleFilter();
                    }
                }}
                value={localSearchString} onChange={(e) => {setlocalSearchString(e.target.value)}}/>
        </div>
    );
}

export default Searchbar;

if (document.getElementsByClassName('searchbar').length > 0) {
    Array.from(document.getElementsByClassName('searchbar')).forEach(el => {
        var data = el.getAttribute('data');
        ReactDOM.render(<Searchbar data={data} />, el);
    });
}
