import React, { useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';

function DiscountCodeDisctanceWrapper(props) {
    const [discountDistances, setDiscountDistances] = useState();
    const [labels, setLabels] = useState();

    useLayoutEffect(()=>{
        let temp = JSON.parse(props.data);
        setDiscountDistances(temp?.discountDistances??[{
            discount_amount: '',
            km_start: 0,
            km_end: '',
        }]);
        setLabels(temp?.labels??[]);
    }, []);

    const addRow = () => {
        let temp = [...discountDistances, {
            discount_amount: '',
            km_start: discountDistances.length>0?discountDistances[discountDistances.length-1].km_end:'',
            km_end: '',
        }];
        setDiscountDistances(temp);
    }

    const changeAmount = (value, index) => {
        let temp = [...discountDistances];
        temp[index].discount_amount = value;
        setDiscountDistances(temp);
    }

    const changeKmStart = (value, index) => {
        let temp = [...discountDistances];
        temp[index].km_start = value;
        if (index > 0) {
            temp[index-1].km_end = value;
        }
        setDiscountDistances(temp);
    }

    const blurKmStart = () => {
        let temp = [...discountDistances];
        setDiscountDistances(reformat(temp));
    }

    const changeKmEnd = (value, index) => {
        let temp = [...discountDistances];
        temp[index].km_end = value;
        if (index < discountDistances.length-1) {
            temp[index+1].km_start = value;
        }
        setDiscountDistances(temp);
    }
    
    const blurKmEnd = () => {
        let temp = [...discountDistances];
        setDiscountDistances(reformat(temp));
    }

    const removeRow = (index) =>{
        let temp = [...discountDistances]; 
        temp.splice(index, 1);
        setDiscountDistances(reformat(temp));
    }

    const reformat = (discountDistancesArray) => {
        let temp = [];
        discountDistancesArray.forEach((d, i) => {
            let kmStart = parseFloat((i>0?temp[i-1].km_end: d.km_start));
            let kmEnd = parseFloat(d.km_end);
            let parsedKmStart = isNaN(kmStart)?0:kmStart;
            let parsedKmEnd = isNaN(kmEnd)?0:kmEnd;

            if ((parsedKmEnd < parsedKmStart && i == discountDistancesArray.length-1 && !isNaN(kmEnd)) || (parsedKmEnd < parsedKmStart && i != discountDistancesArray.length-1)) {
                kmEnd = kmStart;
            }
            temp.push({...d, km_start:kmStart, km_end:kmEnd });
        });
        return temp;
    }

    return (
        <div className='row DiscountCodeDisctanceWrapperRow mb-3'>
            <div className="col-12 mb-3">
                    {labels && discountDistances && discountDistances.length > 0 && discountDistances.map((c, i) => {
                        return <div className="row mb-3" key={i}>
                                    <div className='col-11'>
                                        <div className='row'>
                                            <div className='col-md-4 mb-3 mb-md-0'>
                                                <label htmlFor="name-input-1" >{labels.amount??'amount'}</label><br/>
                                                <div className="input-group">
                                                    <div className="input-group-prepend euroLabel" style={{display: (globalDiscountType == 'percentage' ? 'none':'flex')}}>
                                                        <span className="input-group-text rounded-0 rounded-start" id="price">€</span>
                                                    </div>
                                                    <input type="number" value={discountDistances[i].discount_amount} onChange={(e)=> {changeAmount(e.target.value, i);}} className="form-control discountDistancesInput" name="discountDistances_amount[]" required={true} />
                                                    <div className="percentageLabel input-group-append text-center" style={{minWidth: 0, display: (globalDiscountType == 'amount' ? 'none':'flex')}}>
                                                        <span className="input-group-text rounded-0 rounded-end">%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <label>{labels.km_start??'km_start'}</label><br/>
                                                        <div className="input-group">
                                                            <input type="number" min={0} value={discountDistances[i].km_start} onChange={(e)=> {changeKmStart(e.target.value, i);}} onBlur={(e)=>{blurKmStart(e.target.value, i)}} className="form-control discountDistancesInput" name="discountDistances_km_start[]" required={true} />
                                                            <div className="percentageLabel input-group-append text-center">
                                                                <span className="input-group-text rounded-0 rounded-end">km</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <label htmlFor="breed">{labels.km_end??'km_end'}</label><br/>
                                                        <div className="input-group">
                                                            <input type="number" min={0} value={discountDistances[i].km_end} onChange={(e)=> {changeKmEnd(e.target.value, i);}} onBlur={(e)=>{blurKmEnd(e.target.value, i)}} className="form-control discountDistancesInput" name="discountDistances_km_end[]" required={discountDistances.length-1 != i} />
                                                            <div className="percentageLabel input-group-append text-center">
                                                                <span className="input-group-text rounded-0 rounded-end">km</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1 p-0 d-flex align-items-center">
                                        {i > 0 && 
                                            <button type="button" className="btn btn-danger discountDistancesDelete p-0 px-md-2" onClick={()=> {removeRow(i);}}>
                                                &times;
                                            </button>
                                        }
                                    </div>
                        </div>
                    })}
            </div>
            <div className='col-auto mx-auto'>
                <button type='button' className='col-12 discountDistancesAdd' onClick={()=>{addRow()}}>{labels?.addDiscountDistances??'add'}</button>
            </div>

        </div>
    );
}

export default DiscountCodeDisctanceWrapper;

if (document.getElementsByClassName('DiscountCodeDisctanceWrapper').length > 0) {
    Array.from(document.getElementsByClassName('DiscountCodeDisctanceWrapper')).forEach(el => {
        var data = el.getAttribute('data');
        ReactDOM.render(<DiscountCodeDisctanceWrapper data={data} />, el);
    });
}
