import React from 'react';
import ReactDOM from 'react-dom';

function CargoRow({nameLabel, amountLabel, index, cargo, setCargo}) {

    const changeName = (name) => {
        let temp = [...cargo];
        temp[index].cargo_name = name;
        setCargo(temp);
    }

    const changeAmount = (amount) => {
        let temp = [...cargo];
        temp[index].cargo_amount = amount;
        setCargo(temp);
    }
    return (
        <div className='row cargoRow mb-3'>
            <div className='col-11'>
                <div className='row'>
                    <div className='col-md-6 mb-3 mb-md-0'>
                        <label htmlFor="name-input-1" >{nameLabel??'Name'}</label><br/>
                        <input type="text" id='name-input-1' value={cargo[index].cargo_name} onChange={(e)=> {changeName(e.target.value);}} className="form-control cargoInput w-100" name="cargo_names[]" required={true} />
                    </div>
                    <div className='col-md-6'>
                        <div className='row'>
                            <div className='col-12'>
                                <label htmlFor="breed">{amountLabel??'Amount'}</label><br/>
                                <input type="number" min={1} value={cargo[index].cargo_amount} onChange={(e)=> {changeAmount(e.target.value);}} className="form-control cargoInput w-100" name="cargo_amounts[]" required={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-1 p-0 d-flex align-items-center">
                <button type="button" className="btn btn-danger cargoDelete p-0 px-md-2" onClick={()=> {
                        let temp = [...cargo]; 
                        temp.splice(index, 1);
                        setCargo(temp);
                    }}
                >&times;</button>
            </div>
        </div>
    );
}

export default CargoRow;

if (document.getElementsByClassName('cargorow').length > 0) {
    Array.from(document.getElementsByClassName('cargorow')).forEach(el => {
        var data = el.getAttribute('data');
        ReactDOM.render(<CargoRow data={data} />, el);
    });
}
